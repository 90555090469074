<template>
  <v-container class="pa-10">
    <v-row class="mb-10">
      <v-col class="text-center">
        <span class="grey--text">Please choose organization</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <auth-choose-organization-container />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthChooseOrganizationContainer from '@/components/auth/AuthChooseOrganizationContainer'

export default {
  name: 'AuthLoginView',
  components: { AuthChooseOrganizationContainer }
}
</script>

<style lang="scss" scoped>

</style>
