<template>
  <v-card
    :ripple="{ class: 'primary--text' }"
    class="entity-card"
    elevation="0"
    hover
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-avatar size="80">
      <div class="icon">
        <app-svg
          :src="`~/assets/img/icons/${entityType}-white.svg`"
          alt="icon"
          color="white"
          size="32"
        />
      </div>
    </v-avatar>

    <v-card-title
      class="card-title text-center"
    >
      {{ action }} {{ entityType }}
    </v-card-title>
  </v-card>
</template>

<script>
import AppSvg from '@/components/app/AppSvg'

export default {
  components: { AppSvg },
  props: {
    action: {
      type: String,
      required: true
    },
    entityType: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.v-card.entity-card {
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 2px solid var(--v-grey-lighten1);
  transition: border $secondary-transition,
  background $secondary-transition,
  color $secondary-transition;
  color: var(--v-grey-base);

  .card-title {
    font-size: 1rem;
    font-weight: 500;
    font-family: $heading-font-family;
    color: inherit;
    word-break: break-word;
    transition: none;
  }

  .v-avatar {
    transition: background $secondary-transition;
    background: var(--v-grey-lighten1);
  }

  &:hover,
  &:focus-within,
  &:active {
    background: white;
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);

    .v-avatar {
      background: var(--v-primary-base);
    }
  }
}
</style>
