<template>
  <div class="d-contents">
    <span
      v-if="!isTooltipVisible"
      :class="textClassProp"
      class="list-item-name text-shortened-dots"
    >
      {{ textProp }}
    </span>

    <v-tooltip v-else-if="isTooltipVisible" bottom>
      <template v-slot:activator="{ on, attrs }">
        <span
          :class="textClassProp"
          class="list-item-name text-shortened-dots"
          v-bind="attrs"
          v-on="on"
        >
          {{ textProp }}
        </span>
      </template>
      <span> {{ textProp }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "UserListItemCommonText",

  props: {
    textProp: {
      type: String
    },

    textClassProp: {
      type: String,
      default: 'text-h5'
    }
  },

  data () {
    return {
      isTooltipVisible: false
    }
  },

  methods: {
    checkIfTooltipIsVisible () {
      let userName = this.$el.querySelector('.list-item-name')
      let width = window.getComputedStyle(userName).width

      width = Number(width.split('.')[0])
      return width > 199
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.isTooltipVisible = this.checkIfTooltipIsVisible()
    })
  }
}
</script>

<style lang="scss" scoped>
.d-contents {
  display: contents;
}

.list-item-name {
  width: unset !important;
}

.text-small {
  display: inline-block !important;
}
</style>
