<template>
  <div>
    <user-edit-left-side-panel
      :user-prop.sync="user"
      @showDeleteDialog="showDeleteDialog"
      @showEditCompanyDialog="showEditCompanyDialog"
      @showChangePassDialog="showChangePassDialog"
      @showEditImageDialog="showEditImageDialog"
      @showEditNameDialog="showEditNameDialog"
      @showEditPositionDialog="showEditPositionDialog"
      @showEditDescriptionDialog="showEditDescriptionDialog"
    />

    <app-image-dialog-edit
      v-if="isEditImageDialogVisible"
      :key="updateImageDialogKey + '-change-image-dialog'"
      v-model="isEditImageDialogVisible"
      :image-prop.sync="user.image"
      :is-error-occurred-while-uploading-prop.sync="isErrorOccurredWhileUploading"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :validation-error-message-prop="validationErrorMessage"
      @changeImage="changeUserImage"
    />

    <user-dialog-edit-name
      v-if="isEditNameDialogVisible"
      :key="editNameDialogKey + '-user-dialog'"
      v-model="isEditNameDialogVisible"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :is-name-already-exist-prop.sync="isNameAlreadyExist"
      :user-name-prop="user.name"
      :validation-error-message-prop="validationErrorMessage"
      @changeUserName="changeUserName"
    />

    <user-dialog-edit-company
      v-if="isEditCompanyDialogVisible"
      :key="editCompanyDialogKey + '-user-dialog'"
      v-model="isEditCompanyDialogVisible"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :user-company-prop="user.company"
      :validation-error-message-prop="validationErrorMessage"
      @changeUserCompany="changeUserCompany"
    />

    <user-dialog-edit-position
      v-if="isEditPositionDialogVisible"
      :key="editPositionDialogKey + '-user-dialog'"
      v-model="isEditPositionDialogVisible"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :user-position-prop="user.company_position"
      :validation-error-message-prop="validationErrorMessage"
      @changeUserPosition="changeUserPosition"
    />

    <user-dialog-edit-description
      v-if="isEditDescriptionDialogVisible"
      :key="editDescriptionDialogKey + '-user-dialog-edit-description'"
      v-model="isEditDescriptionDialogVisible"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :user-description-prop="user.description"
      :validation-error-message-prop="validationErrorMessage"
      @changeUserDescription="changeUserDescription"
    />

    <user-dialog-change-password
        v-if="isChangePassDialogVisible"
        :key="changePassDialogKey + '-change-pass-dialog'"
        v-model="isChangePassDialogVisible"
    />

    <user-dialog-delete
      v-if="isDeleteDialogVisible"
      :key="deleteDialogKey + '-delete-dialog'"
      v-model="isDeleteDialogVisible"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      @deleteUser="deleteUser"
    />
  </div>
</template>

<script>
import AppImageDialogEdit from '@/components/app/image/AppImageDialogEdit'

import UserEditLeftSidePanel from '@/components/user/edit/UserEditLeftSidePanel'

import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'
import UserDialogEditPosition from '@/components/user/UserDialogEditPosition'
import UserDialogEditName from '@/components/user/UserDialogEditName'
import UserDialogEditCompany from '@/components/user/UserDialogEditCompany'
import UserDialogChangePassword from '@/components/user/UserDialogChangePassword'
import UserDialogDelete from '@/components/user/UserDialogDelete'
import UserDialogEditDescription from '@/components/user/UserDialogEditDescription'

export default {
  name: "UserEditLeftSidePanelContainer",
  components: {
    UserDialogDelete,
    UserDialogEditCompany,
    UserDialogEditName,
    UserDialogEditPosition,
    UserEditLeftSidePanel,
    UserDialogChangePassword,
    AppImageDialogEdit,
    UserDialogEditDescription
  },

  data () {
    return {
      isEditNameDialogVisible: false,
      editNameDialogKey: 0,

      isEditImageDialogVisible: false,
      updateImageDialogKey: 0,

      isEditCompanyDialogVisible: false,
      editCompanyDialogKey: 0,

      isEditPositionDialogVisible: false,
      editPositionDialogKey: 0,

      isEditDescriptionDialogVisible: false,
      editDescriptionDialogKey: 0,

      isDeleteDialogVisible: false,
      deleteDialogKey: 0,

      isChangePassDialogVisible: false,
      changePassDialogKey: 0,

      actionStateConstants: actionStates,

      validationErrorMessage: '',
      isErrorOccurredWhileUploading: false,
      isNameAlreadyExist: false
    }
  },

  computed: {
    ...mapGetters(
      {
        user: 'user/getUser',
        currentState: 'user/getCurrentState'
      }
    )
  },

  methods: {
    showEditNameDialog () {
      this.isEditNameDialogVisible = true
      this.editNameDialogKey++
    },

    showEditImageDialog () {
      this.isEditImageDialogVisible = true
      this.updateImageDialogKey++
    },

    async changeUserImage (image) {
      try {
        const dataForChangingUserImage = this.prepareFormDataForChangeImage(image)
        await this.$store.dispatch('user/updateUserPicture', dataForChangingUserImage)
      } catch (error) {
        if (error.response.status === 422) {
          this.isErrorOccurredWhileUploading = true
          this.validationErrorMessage = error.response.data.errors.file[0]
        }
      }
    },

    showEditCompanyDialog () {
      this.isEditCompanyDialogVisible = true
      this.editCompanyDialogKey++
    },

    showEditPositionDialog () {
      this.isEditPositionDialogVisible = true
      this.editPositionDialogKey++
    },

    showEditDescriptionDialog () {
      this.isEditDescriptionDialogVisible = true
      this.editDescriptionDialogKey++
    },

    showDeleteDialog () {
      this.isDeleteDialogVisible = true
      this.deleteDialogKey++
    },

    showChangePassDialog () {
      this.isChangePassDialogVisible = true
      this.changePassDialogKey++
    },

    async changeUserName (userName) {
      try {
        await this.$store.dispatch('user/updateUserName', { id: this.user.id, name: userName })
      } catch (error) {
        if (error.response.status === 422) {
          this.isNameAlreadyExist = true
          this.validationErrorMessage = error.response.data.errors.name[0]
        }
      }
    },

    prepareFormDataForChangeImage (image) {
      let bodyFormData = new FormData()

      bodyFormData.set('id', this.user.id)
      bodyFormData.set('file', image)

      return bodyFormData
    },

    async changeUserCompany (company) {
      await this.$store.dispatch('user/updateUserCompany', {
        id: this.user.id,
        company: company
      })
    },

    async changeUserPosition (position) {
      await this.$store.dispatch('user/updateUserPosition', {
        id: this.user.id,
        company_position: position
      })
    },

    async changeUserDescription (description) {
      await this.$store.dispatch('user/updateUserDescription', {
        id: this.user.id,
        description: description
      })
    },

    async deleteUser () {
      try {
        await this.$store.dispatch('user/deleteUser', { id: this.user.id })
      } finally {
        await this.$router.replace('/users')
      }

    }
  }
}
</script>

<style scoped>

</style>
