<template>
  <app-dialog
    :value="isDialogVisible"
    title-prop="Add products"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="list-body py-3">
      <app-list-item
        v-for="(product,index) in allProductsData"
        :key="`product-${index}`"
        :selected="selectedProductItems.includes(product) || product.selected"
        hover
        @update:selected="onItemSelectToggle($event, product)"
      >
        <v-avatar class="image-border px-1" rounded size="60">
          <v-img
            :alt="`${product.name} image`"
            :src="product.logo && product.logo.url"
            contain
          />
        </v-avatar>

        <template #title>
          <span>{{ product.name }}</span>
        </template>
      </app-list-item>
    </div>

    <template #actions>
      <v-spacer />
      <v-btn
        :disabled="isLoadingProp"
        :loading="isLoadingProp"
        color="primary"
        min-width="200"
        rounded
        x-large
        @click="saveUserProduct"
      >
        Confirm
      </v-btn>
    </template>
  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'
import AppListItem from '@/components/app/AppListItem'

export default {
  name: "UserDialogEditProducts",
  components: { AppListItem, AppDialog },
  props: {
    value: {
      default: true
    },

    productsProps: {
      type: Array,
      default: () => {
        return []
      }
    },

    userSelectedProductsProp: {
      type: Array,
      default: () => {
        return []
      }
    },

    isLoadingProp: {
      type: Boolean
    }
  },

  data () {
    return {
      selectedProductItems: null,
      allProductsData: null
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', !!value)
      }
    }
  },

  methods: {
    onItemSelectToggle (isCheckboxSelected, item) {
      if (isCheckboxSelected) {
        this.selectedProductItems.push(item)
        return
      }

      item.selected = false
      this.selectedProductItems = this.selectedProductItems.filter((oneSelectedItem) => oneSelectedItem.id !== item.id)
    },

    saveUserProduct () {
      this.$emit('saveUserProduct', this.selectedProductItems)
    }
  },

  mounted () {
    this.selectedProductItems = [...this.userSelectedProductsProp]

    this.allProductsData = this.productsProps.map((product) => (
        {
          ...product,
          selected: this.selectedProductItems.some(selectedProductItem => selectedProductItem.id === product.id)
        }
      )
    )
  }
}
</script>

<style lang="scss" scoped>
.list-body {
  background-color: #c3c3c31a;
}
</style>
