<template>
  <app-dialog
    v-model="isDialogVisible"
    :persistent="isLoadingProp"
    :title-prop="'Add User'"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <v-text-field
        id="email-field"
        v-model="userEmail"
        :error-messages="emailErrors"
        :placeholder="'E-mail'"
        clearable
        label="Email"
        required
        @blur="$v.$touch()"
        @input="$v.userEmail.$touch()"
      />
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            :disabled="isLoadingProp"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click.native="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            :disabled="isLoadingProp || $v.$anyError || isEmailAlreadyExistProp"
            :loading="isLoadingProp"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="createUser"
          >
            <span class="text-capitalize">
              Send invitation
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'
import { email, required } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: "UserDialogCreate",
  components: { AppDialog },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    isLoadingProp: {
      type: Boolean,
      default: false,
      required: true
    },

    isEmailAlreadyExistProp: {
      type: Boolean,
      default: false
    },

    validationErrorMessageProp: {
      type: String,
      default: ''
    }
  },

  validations: {
    userEmail: {
      required,
      email
    }
  },

  data () {
    return {
      userEmail: ''
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    emailErrors () {
      const errors = []
      if (!this.$v.userEmail.$dirty) {
        return errors
      }

      this.isEmailAlreadyExistProp && errors.push(this.validationErrorMessageProp)
      !this.$v.userEmail.email && errors.push('E-mail format must be valid')
      !this.$v.userEmail.required && errors.push('E-mail is required')
      return errors
    }
  },

  watch: {
    userEmail () {
      if (this.isEmailAlreadyExistProp) {
        this.$emit('update:isEmailAlreadyExistProp', false)
        this.$emit('update:validationErrorMessageProp', '')
      }
    }
  },

  methods: {
    hideDialog () {
      this.resetUserEmail()
      this.isDialogVisible = false
    },

    resetUserEmail () {
      this.userEmail = null
    },

    createUser () {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      this.$emit('createUser', { id: uuidv4(), email: this.userEmail, roles: ["member"] })
    }
  }
}
</script>

<style scoped>

</style>
