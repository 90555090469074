<template>
  <div>
    <h1 class="mb-6">
      Products
    </h1>

    <v-divider class="mt-1 mb-8" />

    <v-row>
      <v-col
        v-for="(product, index) in products"
        :key="'product-' + index"
        :cols="12"
        lg="2"
        md="3"
        sm="6"
        xs="12"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            :class="{ 'on-hover': hover }"
            :elevation="hover ? 10 : 2"
            class="mx-auto"
            height="200"
            link
            max-height="250"
            max-width="500"
            outlined
            @click.native="productClick(product)"
          >
            <v-img
              :src="product.logo && product.logo.url"
              class="mx-10"
              contain
              height="150"
            />

            <v-divider />

            <div class="card-content">
              <v-card-title>
                <span class="text-h5 list-item-name text-shortened-dots">{{ product.name }}</span>
              </v-card-title>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'HomeView',

  computed: {
    ...mapGetters(
      {
        products: 'product/getProducts'
      }
    )
  },

  methods: {
    productClick (product) {
      this.$router.push(`/products/${product.id}`)
    },
  },

  async created () {
    await this.$store.dispatch('product/fetchProducts')
  }
}
</script>
