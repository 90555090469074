<template>
  <div>
    <app-single-item-edit-left-section
      v-if="userProp"
      :entity-item-prop="userProp"
      :entity-type-prop="'user'"
      @showEditEmailDialog="$emit('showEditEmailDialog')"
      @showEditImageDialog="$emit('showEditImageDialog')"
      @showEditNameDialog="$emit('showEditNameDialog')"
    >
      <template #other-edit-fields>
        <div class="text-regular text-capitalize text-center user-name">
          {{ role }}
        </div>

        <div class="text-regular text-justify pt-8 pb-5">
          <div class="email-text">
            <span class="entity-fields-text-span">
              <b> Email: </b>{{ userProp.email }}
            </span>
          </div>
        </div>

        <div class="text-regular text-justify pb-5">
          <div class="d-inline font-weight-bold">Company:</div>
          <v-hover>
            <template v-slot="{ hover }">
              <div
                class="company-text hover-text-effect d-inline"
                style="cursor:pointer;"
                @click="$emit('showEditCompanyDialog')"
              >
              <span
                v-if="userProp.company"
                class="entity-fields-text-span"
              >
               {{ userProp.company }}
              </span>

                <span
                  v-else-if="!userProp.company"
                  class="entity-fields-text-span "
                >
                is empty click here to edit it
              </span>

                <v-btn
                  v-if="true"
                  :class="{ 'element-invisible': !hover }"
                  class="entity-fields-edit-btn ml-1"
                  color="primary"
                  height="22"
                  icon
                  small
                  width="22"
                >
                  <app-svg
                    size="14"
                    src="~/assets/img/icons/edit.svg"
                  />
                </v-btn>
              </div>
            </template>

          </v-hover>
        </div>

        <div class="text-regular text-justify pb-5">
          <div class="d-inline font-weight-bold">Position:</div>
          <v-hover v-slot="{ hover }">
            <div
              class="position-text hover-text-effect d-inline"
              style="cursor:pointer;"
              @click="$emit('showEditPositionDialog')"
            >
              <span
                v-if="userProp.company_position"
                class="entity-fields-text-span"
              >
               {{ userProp.company_position }}
              </span>

              <span
                v-else-if="!userProp.company_position"
                class="entity-fields-text-span "
              >
                is empty click here to edit it
              </span>

              <v-btn
                v-if="true"
                :class="{ 'element-invisible': !hover }"
                class="entity-fields-edit-btn ml-1"
                color="primary"
                height="22"
                icon
                small
                width="22"
              >
                <app-svg
                  size="14"
                  src="~/assets/img/icons/edit.svg"
                />
              </v-btn>
            </div>
          </v-hover>
        </div>

        <div class="text-regular text-justify pb-5">
          <div class="d-inline font-weight-bold">Description:</div>
          <v-hover>
            <template v-slot="{ hover }">
              <div
                class="position-text hover-text-effect d-inline"
                style="cursor:pointer;"
                @click="$emit('showEditDescriptionDialog')"
              >
              <span
                v-if="userProp.description"
                class="entity-fields-text-span"
              >
               {{ userProp.description }}
              </span>

                <span
                  v-else-if="!userProp.description"
                  class="entity-fields-text-span "
                >
                is empty click here to edit it
              </span>

                <v-btn
                  v-if="true"
                  :class="{ 'element-invisible': !hover }"
                  class="entity-fields-edit-btn ml-1"
                  color="primary"
                  height="22"
                  icon
                  small
                  width="22"
                >
                  <app-svg
                    size="14"
                    src="~/assets/img/icons/edit.svg"
                  />
                </v-btn>
              </div>
            </template>

          </v-hover>
        </div>

        <div
          v-if="isCurrentUserSameAsUser"
          id="change-password-dialog"
          class="mt-2 mb-0 py-2"
          @click="$emit('showChangePassDialog')"
        >
          <v-hover v-slot="{ hover }">
            <span :class="hover ? 'change-pass-hover' : ''" class="field-editable-underline text-select-none">
              Change password
            </span>
          </v-hover>
        </div>

        <div v-if="currentUser.access.delete_user && !isCurrentUserSameAsUser" class="text-regular pb-5">
          <v-tooltip
            bottom
            color="primary"
          >
            <span>Remove</span>

            <template #activator="{ on, attrs }">
              <v-btn
                id="show-delete-user-dialog"
                class=""
                color="primary"
                icon
                v-bind="attrs"
                @click="$emit('showDeleteDialog')"
                v-on="on"
              >
                <app-svg
                  size="24"
                  src="~/assets/img/icons/delete.svg"
                />
              </v-btn>
            </template>

          </v-tooltip>
        </div>
      </template>
    </app-single-item-edit-left-section>
  </div>
</template>

<script>
import AppSingleItemEditLeftSection from '@/components/app/AppSingleItemEditLeftSection'
import AppSvg from '@/components/app/AppSvg'

import { mapGetters } from 'vuex'

export default {
  name: "UserEditLeftSidePanel",
  components: {
    AppSvg,
    AppSingleItemEditLeftSection
  },

  props: {
    userProp: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    ...mapGetters({
        currentUser: 'auth/getCurrentUser'
      }),

    role () {
      if (this.userProp.roles?.length > 0) {
        return this.userProp.roles[0].name
      }

      return ''
    },

    isCurrentUserSameAsUser () {
      return this.currentUser.id === this.userProp.id
    }
  }
}
</script>

<style lang="scss" scoped>
.user-name {
  color: var(--v-grey-base)
}

.change-pass-hover {
  color: var(--v-primary-base)
}

</style>
