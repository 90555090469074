<template>
  <div>
    <!--    HEADER-->
    <v-row class="d-flex justify-space-between">
      <v-col>
        <!--    <div> Select, Sort Filter goes here</div>-->
      </v-col>

      <v-col class="text-right" >
          <v-btn
            class="px-2 overlay-exclude"
            color="primary"
            rounded
            small
            text
            :disabled="!selectedUsers.length"
            @click="isDetachUsersDialogVisible = true"
          >
            <div class="guttered-row dense">
              <app-svg
                size="16"
                src="~/assets/img/icons/delete.svg"
              />
              <span>Remove</span>
            </div>
          </v-btn>
      </v-col>

    </v-row>

     <v-divider class="mt-2" />

    <!--    ADD NEW USERS-->
    <div
      class="add-new-items-btn d-flex align-center pa-5 my-5"
      @click="addNewUsersClick"
    >
      <v-avatar size="60">
        <div class="icon">
          <app-svg
            alt="icon"
            color="white"
            size="32"
            src="~/assets/img/icons/plus.svg"
          />
        </div>
      </v-avatar>

      <div class="section-title ml-5">
        Add new users
      </div>
    </div>

<!--    ATTACHED USERS-->

    <div>
      <div v-if="productAttachedUsers.hasAnyUsers">
        <product-users-expansion-panel
          :users-grouped-by-role="productAttachedUsers"
          :has-inline-delete-action="true"
          @showUserDetachDialog="showUserDetachDialog"
        />
      </div>

      <div v-else class="text-regular text-info text-center">
        No attached users
      </div>
    </div>


    <product-dialog-add-users
      v-if="isAddUsersDialogVisible"
      v-model="isAddUsersDialogVisible"
    />

    <product-dialog-remove-users
      v-if="isDetachUsersDialogVisible"
      v-model="isDetachUsersDialogVisible"
      @detachUsers="detachUsers"
    />
  </div>
</template>



<script>

import AppSvg from '@/components/app/AppSvg'
import ProductUsersExpansionPanel from '@/components/product/tabs/user/ProductUsersExpansionPanel'
import ProductDialogAddUsers from '@/components/product/tabs/user/ProductDialogAddUsers'
import ProductDialogRemoveUsers from '@/components/product/tabs/user/ProductDialogRemoveUsers'

import { mapGetters } from 'vuex'

export default {
  name: "ProductTabUsersContainer",
  components: {
    AppSvg,
    ProductUsersExpansionPanel,
    ProductDialogAddUsers,
    ProductDialogRemoveUsers
  },

  data () {
    return {
      isAddUsersDialogVisible: false,
      isDetachUsersDialogVisible: false,
      currentUserToDetach: null
    }
  },

  computed: {
    ...mapGetters(
      {
        productAttachedUsers: 'product/getProductAttachedUsers',
        selectedUsers: 'product/getSelectedUsers'
      }
    )
  },

  methods: {
    async addNewUsersClick () {
      this.isAddUsersDialogVisible = true
    },
    
    showUserDetachDialog () {
      this.isDetachUsersDialogVisible = true
    },

    async detachUsers () {
      const payload = {
        product_id: this.$route.params.id,
        user_ids: this.selectedUsers.map(item => item.id)
      }
      await this.$store.dispatch('product/detachUsersFromProduct', payload)
    }
  }
}
</script>

<style scoped lang="scss">

</style>