import $axios from '../plugins/axios'

class OrganizationService {
  getAllOrganizations () {
    return $axios.get('/get-organizations')
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getAllUserInvitations () {
    return $axios.get('/get-user-invitations')
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new OrganizationService()
