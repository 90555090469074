import UserService from "@/services/user.service"
import { actionStates } from '@/constants'
import OrganizationsService from '@/services/оrganizations.service'
import ProductService from '@/services/product.service'
import AuthService from '@/services/auth.service'
import InvitationService from '@/services/invitation.service'

import { UserClass } from '@/classes/users/UserClass'

import Vue from 'vue'

const state = {
  currentState: actionStates.INITIAL,
  user: {},
  allAvailableProducts: [],
  userSelectedOrganization: null,
  userOrganizations: [],
  userProducts: []
}

const getters = {
  getUser (state) {
    return state.user
  },

  getCurrentState (state) {
    return state.currentState
  },

  getUserSelectedOrganization (state) {
    return state.userSelectedOrganization
  },

  getAllAvailableProducts (state) {
    return state.allAvailableProducts
  },

  getUserProducts (state) {
    return state.userProducts
  },

  getUserOrganizations (state) {
    return state.userOrganizations
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setUser (state, user) {
    state.user = user ?? []
  },

  setUserSelectedOrganization (state, userSelectedOrganization) {
    state.userSelectedOrganization = userSelectedOrganization ?? null
  },

  setAllAvailableProducts (state, allAvailableProducts) {
    state.allAvailableProducts = allAvailableProducts ?? null
  },

  setUserProducts (state, allAvailableProducts) {
    state.userProducts = allAvailableProducts ?? null
  },

  setUserOrganizations (state, userOrganizations) {
    state.userOrganizations = userOrganizations ?? null
  }
}

const actions = {
  async resetUser (vuexContext) {
    vuexContext.commit('setUser', {})
  },

  async fetchUserOrganizationIfMissing (vuexContext) {
    if (!vuexContext.getters.getUserSelectedOrganization?.id) {
      let organization = await AuthService.getOrganization()
      await vuexContext.dispatch('updateUserSelectedOrganization', organization)
    }
  },

  async fetchUserProducts (vuexContext) {
    let currentUser = vuexContext.getters.getUser
    await vuexContext.dispatch('fetchUserOrganizationIfMissing')
    let products = await ProductService.getUserProducts(currentUser.id)
    vuexContext.commit('setUserProducts', products)
  },

  async fetchOrganizationProducts (vuexContext) {
    await vuexContext.dispatch('fetchUserOrganizationIfMissing')
    let products = await ProductService.getOrganizationProducts()
    vuexContext.commit('setAllAvailableProducts', products)
  },

  async fetchUser (vuexContext, id) {
    vuexContext.commit('setActionState', actionStates.LOADING)
    vuexContext.dispatch('resetUser')

    try {
      let response = await UserService.getOneUser(id)
      const user = new UserClass(response)
      vuexContext.commit('setUser', user)
      await vuexContext.dispatch('fetchUserProducts')
      await vuexContext.dispatch('fetchOrganizationProducts')

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },

  async updateCurrentUser (vuexContext) {
    let currentUser = vuexContext.getters.getUser
    vuexContext.dispatch('fetchUser', currentUser.id)
  },

  async updateUserName (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await UserService.changeUserName(data)
      await vuexContext.dispatch('updateCurrentUser')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async updateUserPicture (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await UserService.changeUserImage(data)
      await vuexContext.dispatch('updateCurrentUser')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async updateUserCompany (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await UserService.changeUserCompany(data)
      await vuexContext.dispatch('updateCurrentUser')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async updateUserDescription (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await UserService.changeUserDescription(data)
      await vuexContext.dispatch('updateCurrentUser')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async updateUserPosition (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await UserService.changeUserPosition(data)
      await vuexContext.dispatch('updateCurrentUser')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async updateUserProducts (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await vuexContext.dispatch('fetchUserOrganizationIfMissing')
      await UserService.changeUserProducts(data)
      await vuexContext.dispatch('updateCurrentUser')
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async deleteUser (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await UserService.deleteUser(data)
      vuexContext.commit('setActionState', actionStates.INITIAL)
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async fetchUserOrganizations (vuexContext) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let organizations = await OrganizationsService.getAllOrganizations()
      let organizationInvitations = await OrganizationsService.getAllUserInvitations()
      organizationInvitations = organizationInvitations.map(organization => ({ ...organization, invite: true }))

      vuexContext.commit('setUserOrganizations', [...organizations, ...organizationInvitations])
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },

  async updateUserSelectedOrganization (vuexContext, data) {
    if (vuexContext.getters.getUserSelectedOrganization?.id === data.id) {
      vuexContext.commit('setUserSelectedOrganization', null)
      return
    }

    vuexContext.commit('setUserSelectedOrganization', data)
  },

  async inviteNewUser (vuexContext, data) {
    vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_LOADING)

    try {
      await vuexContext.dispatch('fetchUserOrganizationIfMissing')
      await UserService.inviteUser({ ...data, organization_id: vuexContext.getters.getUserSelectedOrganization.id })
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.DIALOG_SUBMIT_ERROR)
      throw e
    }
  },

  async getAllUsers (vuexContext) {
    vuexContext.commit('setActionState', actionStates.LOADING)
    vuexContext.dispatch('resetUser')

    try {
      await vuexContext.dispatch('fetchUserOrganizationIfMissing')
      let activeUsers = await UserService.getAllUsers()
      let invitedUsers = await UserService.getAllInvitedUsers()
      invitedUsers = invitedUsers.map(user => ({ ...user, pending: true }))

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
      return [...activeUsers, ...invitedUsers]
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },

  async checkInvitationExists (vuexContext, data) {
    return await UserService.checkInvitationExists(data)
  },

  async acceptInvitation (vuexContext, invitationId) {
    await InvitationService.acceptInvitation({ invitation_id: invitationId })
    await vuexContext.dispatch('fetchUserOrganizations')
  },

  async declineInvitation (vuexContext, invitationId) {
    await InvitationService.declineInvitation({ invitation_id: invitationId })
    await vuexContext.dispatch('fetchUserOrganizations')
  },

  activateUser (vuexContext, data) {
    return UserService.activateUser(data)
      .then((res) => {
        Vue.set(vuexContext.state.user, 'enabled', true)
        return res
      })
      .catch((e) => {
        throw e
      })
  },

  deactivateUser (vuexContext, data) {
    return UserService.deactivateUser(data)
      .then((res) => {
        Vue.set(vuexContext.state.user, 'enabled', false)
        return res
      })
      .catch((e) => {
        throw e
      })
  }
}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
