import $axios from '../plugins/axios'

class InvitationService {
  acceptInvitation (data) {
    return $axios.post(`/accept-invitation`, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  declineInvitation (data) {
    return $axios.post(`/decline-invitation`, data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

}

export default new InvitationService()
