<template>
  <div>
    <app-subpage-header
      v-if="currentUser.access.tabs.users.access"
      :back-link-prop="'/users'"
      :title-prop="'Users'"
    />

    <app-loader-content
      v-if="currentState === actionStateConstants.LOADING"
    />

    <user-single-overview
      v-else-if="currentState !== actionStateConstants.LOADING"
    />
  </div>
</template>

<script>
import AppSubpageHeader from '@/components/app/AppSubpageHeader'
import AppLoaderContent from '@/components/app/AppLoaderContent'
import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'
import UserSingleOverview from '@/components/user/UserSingleOverview'

export default {
  name: "UserSingleView",
  components: {
    AppLoaderContent,
    AppSubpageHeader,
    UserSingleOverview
  },

  props: {
    isLoadingProp: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        currentState: 'user/getCurrentState',
        currentUser: 'auth/getCurrentUser'
      }
    )
  },

  methods: {
    async getUserInfo () {
      try {
        await this.$store.dispatch('user/fetchUser', this.$route.params.id)
      } catch (error) {
        await this.$router.replace('/users')
      }
    }
  },

  mounted () {
    this.getUserInfo()
  }
}
</script>

<style scoped>

</style>
