<template>
  <div>
    <v-card
      v-if="showConfirmDeclineDialog === false"
      class="v-card--bordered ml-auto mr-auto"
      style="cursor: unset;"
      width="280px"
    >
      <div class="card-image">
        <div style="z-index: 3; position: absolute; top: 15px; right: 15px;">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-3"
                height="20px"
                min-width="20px"
                rounded
                style="cursor: unset;"
                v-bind="attrs"
                width="20px"
                x-small
                v-on="on"
              >
                <img alt="info-icon" src="@/assets/img/icons/info-icon.png" />
              </v-btn>
            </template>
            <span> You have been invited to join {{ entity.organization.name }}</span>
          </v-tooltip>

          <v-btn
            rounded
            style="cursor: unset;"
            x-small
          >
            New invitation
          </v-btn>
        </div>

        <v-img
          :src="entity.organization.logo ? entity.organization.logo.url : ''"
          class="mx-10 v-card--disabled"
          contain
          height="111"
        />
      </div>

      <v-card-subtitle class="py-4 pl-5">
        <div class="d-flex justify-space-between">
          <div>
            <div class="text-h5 list-item-name text-shortened-dots text--disabled">
              {{ entity.organization.name }}
            </div>
          </div>

          <div>
            <v-btn
              class="text-decoration-underline"
              color="red"
              text
              x-small
              @click.native="showConfirmDeclineDialog = true"
            >
              Decline
            </v-btn>

            <v-btn
              color="primary"
              outlined
              rounded
              x-small
              @click.native="$emit('acceptInvitation')"
            >
            <span class="text-capitalize">
              Accept
            </span>
            </v-btn>
          </div>
        </div>
      </v-card-subtitle>
    </v-card>

    <v-card
      v-else-if="showConfirmDeclineDialog === true"
      class="v-card--bordered ml-auto mr-auto"
      height="170px"
      style="cursor: unset;"
      width="280px"
    >
      <div
        class="d-flex align-center flex-column"
        style="height:170px; justify-content: space-evenly;"
      >
        <div class="grey--text text-center" style="font-size: 0.875rem;">
          Are you sure you want to <br>
          decline the invitation?
        </div>

        <div>
          <v-btn
            color="primary"
            outlined
            rounded
            x-small
            @click.native="showConfirmDeclineDialog = false"
          >
            Undo
          </v-btn>

          <v-btn
            class="ml-4"
            color="primary"
            rounded
            x-small
            @click.native="$emit('declineInvitation')"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'ListItemCommonOrganizationInvite',
  props: {
    entity: {
      type: Object,
      required: true
    },

    selectable: {
      type: Boolean,
      required: false,
      default: false
    },

    minWidthProp: {
      type: String,
      default: '249px'
    }
  },

  data () {
    return {
      showConfirmDeclineDialog: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.v-card {
  .card-image {
    background: var(--v-grey-lighten2);
  }

  &__subtitle {
    padding-right: 10px;
  }

  .select-checkbox {
    opacity: 0;
    visibility: hidden;
    transform: translateX(5px);
    transition: transform $secondary-transition,
    opacity $secondary-transition,
    visibility $secondary-transition;
  }

  .list-item-name {
    color: var(--v-grey-darken2);
    width: 135px;
    overflow: hidden;
    white-space: nowrap;

    .theme--light.v-btn {
      color: var(--v-grey-darken1);

    }

    @media (min-width: 1264px) and (max-width: 1700px) {
      width: 110px;
    }
  }

  &:hover,
  &.selected {
    .select-checkbox {
      opacity: 1;
      visibility: visible;
      transform: translateX(0px);
    }

    .list-item-name {
      color: var(--v-primary-base)
    }
  }
}
</style>
