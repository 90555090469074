<template>
  <v-container class="pa-10">
    <v-row>
      <v-col class="text-center">
        <span class="grey--text">Welcome! Please log in to your account.</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-form ref="LoginForm" @submit.prevent="authenticate">
          <v-text-field
            id="login-email"
            v-model="email"
            :error-messages="emailErrors"
            class="mb-6"
            hint="Enter your e-mail"
            label="E-mail"
            name="input-email"
            type="text"
            @blur="$v.email.$touch()"
          />

          <v-text-field
            id="password"
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :error-messages="passwordErrors"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="off"
            hint="Enter your password"
            label="Password"
            name="input-password"
            @blur="$v.password.$touch()"
            @click:append="showPassword = !showPassword"
          />

          <v-row class="my-2 align-center">
            <v-col cols="6">
              <v-checkbox
                ref="remember-me-checkbox"
                v-model="isRememberMe"
                color="primary"
                cols="6"
                label="Remember me"
              />
            </v-col>

            <v-col class="text-right" cols="6">
              <a
                id="forgotten-password-link"
                @click="$router.push('/forgotten-password')"
              >
                Forgotten Password
              </a>
            </v-col>
          </v-row>

          <v-row v-if="errors.length" class="my-0">
            <v-col
              v-if="errors.includes(401)"
              id="error-invalid-credentials"
              class="text-center text-error pa-1"
              cols="12"
            >
              Invalid credentials!
              You must use an already registered account.
            </v-col>

            <v-col
              v-else
              id="error-other"
              class="text-center text-error pa-1"
              cols="12"
            >
              An error occurred! Please check and try again.
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-center">
              <v-btn
                id="login-btn"
                :disabled="$v.$anyError"
                :loading="isButtonLoading"
                color="primary"
                raised
                rounded
                type="submit"
                width="200"
                x-large
              >
                Log in
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'

export default {
  name: 'AuthLoginView',

  validations: {
    email: {
      required,
      email
    },

    password: {
      required
    }
  },

  data () {
    return {
      email: null,
      password: null,
      errors: [],
      isRememberMe: false,
      isButtonLoading: false,
      showPassword: false
    }
  },

  computed: {
    emailErrors () {
      const errors = []

      if (!this.$v.email.$dirty) {
        return errors
      }

      !this.$v.email.required && errors.push('E-mail is required')
      !this.$v.email.email && errors.push('E-mail format is invalid')
      return errors
    },

    passwordErrors () {
      const errors = []

      if (!this.$v.password.$dirty) {
        return errors
      }

      !this.$v.password.required && errors.push('Password is required')
      return errors
    }
  },

  methods: {
    async authenticate () {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      this.isButtonLoading = true
      try {
        await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password,
          isRememberMe: this.isRememberMe
        })
        await this.$router.replace('/choose-organization')
      } catch (error) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Wrong username or password',
          duration: 4000,
          mode: 'fail'
        })
      } finally {
        this.isButtonLoading = false
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      document.querySelector(`label[for='login-email']`).className += ' v-label--active'
      document.querySelector(`label[for='password']`).className += ' v-label--active'
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
