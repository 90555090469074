<template>
  <div v-if="currentUser">
    <the-default-layout-wrap>
      <router-view></router-view>
    </the-default-layout-wrap>
  </div>
</template>

<script>
import TheDefaultLayoutWrap from "./layouts/TheDefaultLayoutWrap.vue"

import { mapGetters } from 'vuex'

export default {
  name: 'AppContent',
  components: {
    TheDefaultLayoutWrap
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser'
    }),
  },

  methods: {
    setDefaultAPIURL (slug) {
      this.$axios.defaults.baseURL = `${ window.location.protocol }//${ slug }${ process.env.VUE_APP_API_OMT_BASE_URL }/api/v1`
    }
  },

  async created () {
    let slug = window.location.hostname.split(`${ process.env.VUE_APP_OMT_FRONT_END_BASE_URL }`)[0]

    if (!slug) {
      window.location.replace(`${ window.location.protocol }//${ process.env.VUE_APP_OMT_FRONT_END_BASE_URL }:${ window.location.port }/choose-organization`)
      return
    }

    await this.setDefaultAPIURL(slug)
    await this.$store.dispatch('auth/getMe')
  },

  async mounted () {
    try {
      await this.$store.dispatch('user/fetchUserOrganizationIfMissing')
    } catch (error) {
      if (error.response.status === 403) {
        this.$router.replace('/no-access-organization')
      }
    }
  }
}
</script>
