<template>
  <div>
    <app-loader-listing  v-if="loading" />

    <div v-else>
      <!--    ADD NEW VERSIONS-->
      <div
        class="add-new-items-btn d-flex align-center pa-5 my-5"
        v-if="currentUser.access.tabs.versions.add_new_versions"
        @click="showAddVersionDialog=true"
      >
        <v-avatar size="60">
          <div class="icon">
            <app-svg
              alt="icon"
              color="white"
              size="32"
              src="~/assets/img/icons/plus.svg"
            />
          </div>
        </v-avatar>

        <div class="section-title ml-5">
          Add new version
        </div>
      </div>

      <v-expansion-panels
        v-for="(version, index) in versions"
        :key="index"
        accordion
        flat
        focusable
        multiple
        hover
        tile
      >
        <v-hover v-slot="{ hover }">
          <app-expansion-panel
            fit
            v-model="version.panelOpened"
            @click="version.panelOpened = !version.panelOpened"
          >
            <template #header>
              <div class="d-flex justify-space-between pr-3">
                <div class="px-2" >
                  {{ version.version }}
                </div>

                <div v-if="((hover || version.panelOpened) && currentUser.access.tabs.versions.edit_version)">
                  <v-btn
                    fab
                    x-small
                    icon
                    color="primary"
                    @click.stop="editVersionClick(version)"
                  >
                    <app-svg
                      alt="icon"
                      color="primary"
                      size="14"
                      src="~/assets/img/icons/edit.svg"
                    />
                  </v-btn>

                </div>
              </div>
            </template>

            <div class="pl-7">
              {{ version.log }}
            </div>

          </app-expansion-panel>
        </v-hover>

      </v-expansion-panels>

      <product-dialog-edit-version
        v-if="showEditVersionDialog"
        v-model="showEditVersionDialog"
        :version-prop="currentVersion"
        @refresh="getVersions"
      />

      <product-dialog-create-version
        v-if="showAddVersionDialog"
        v-model="showAddVersionDialog"
        @refresh="getVersions"
      />

    </div>
  </div>

</template>

<script>
import AppExpansionPanel from '@/components/app/AppExpansionPanel'
import AppSvg from '@/components/app/AppSvg'
import ProductDialogEditVersion from '@/components/product/tabs/version/ProductDialogEditVersion'
import ProductDialogCreateVersion from '@/components/product/tabs/version/ProductDialogCreateVersion'
import ProductService from '@/services/product.service'
import AppLoaderListing from '@/components/app/AppLoaderListing'

import { mapGetters } from 'vuex'

export default {
  name: "ProductTabVersionsContainer",
  components: {
    AppLoaderListing,
    AppExpansionPanel,
    AppSvg,
    ProductDialogEditVersion,
    ProductDialogCreateVersion
  },

  data () {
    return {
      showEditVersionDialog: false,
      showAddVersionDialog: false,
      versions: null,
      loading: false,
      currentVersion: null,
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser'
    }),

  },

  methods: {
    editVersionClick (version) {
      this.currentVersion = version
      this.showEditVersionDialog = true
    },

    async getVersions () {
      try {
        this.loading = true
        this.versions =  await ProductService.getProductVersions(this.$route.params.id)
      } catch (e) {
        this.$router.replace('/dashboard')
      } finally {
        this.loading = false
      }
    },
  },


  async created () {
    await this.getVersions()
  },

  beforeDestroy () {
    this.currentVersion = null
  }
}
</script>

<style scoped lang="scss">
</style>