<template>
  <div class="entity-details-section my-4 mx-auto mx-md-4">
    <app-image-non-editable-holder
      :image-url-prop="entityItemProp.logo && entityItemProp.logo.url"
      :entity-name-prop="entityItemProp.name"
      :img-cover-prop="false"
    />

    <div class="text-center pt-6">
        <h2 class="my-1 mx-auto">
          <span
            class="entity-fields-text-span font-weight-bold"
            style="word-break: break-word;"
          >
            {{ entityItemProp.name }}
          </span>
        </h2>
    </div>

    <slot name="other-fields" />
  </div>
</template>

<script>

import AppImageNonEditableHolder from '@/components/app/image/AppImageNonEditableHolder'

export default {
  name: "AppSingleItemLeftSection",
  components: {AppImageNonEditableHolder },

  props: {
    entityItemProp: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
