<template>
  <div class="flex-grow-1 ma-4">
      <v-tabs
        v-model="selectedTab"
        active-class="tab-active"
        class="mb-4"
        hide-slider
        show-arrows
      >
        <v-tab
          key="users"
          href="#users"
          v-if="currentUser.access.tabs.users.access"
        >
          Users
        </v-tab>

        <v-tab
          key="versions"
          href="#versions"
          v-if="currentUser.access.tabs.versions.access"
        >
          Versions
        </v-tab>

        <v-tab
          key="settings"
          href="#settings"
          disabled
        >
          Settings
        </v-tab>
      </v-tabs>

      <div class="tabs-content-section pa-5">
        <v-tabs-items v-model="selectedTab">
          <v-tab-item
            key="users"
            value="users"
          >
            <product-tab-users-container />
          </v-tab-item>

          <v-tab-item
            key="versions"
            value="versions"
          >
            <product-tab-versions-container />
          </v-tab-item>

          <v-tab-item
            key="settings"
            value="settings"
          >
            <div>some settings here</div>
          </v-tab-item>

        </v-tabs-items>
      </div>

    </div>
</template>

<script>
import ProductTabUsersContainer from '@/components/product/tabs/user/ProductTabUsersContainer'
import ProductTabVersionsContainer from '@/components/product/tabs/version/ProductTabVersionsContainer'

import { mapGetters } from 'vuex'

export default {
  name: "ProductRightSidePanel",
  components: { ProductTabVersionsContainer, ProductTabUsersContainer },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser'
    }),

    selectedTab: {
      get () {
        return this.$route.query.tab
      },
      async set (tab) {
        await this.$router.push({
          query: { ...this.$route.query, tab }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>