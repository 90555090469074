<template>
  <v-app dark>
    <the-default-layout-wrap-app-bar @redirectToHomePage="redirectToHomePage" />

    <the-default-layout-wrap-menu-list
      v-if="menuItems"
      :items-prop="menuItems"
    />

    <v-main>
      <transition mode="out-in" name="scale">
        <v-container :key="$router.currentRoute.path" class="pa-10" fluid>
          <router-view></router-view>
        </v-container>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import TheDefaultLayoutWrapMenuList from './TheDefaultLayoutWrapMenuList.vue'
import TheDefaultLayoutWrapAppBar from '@/layouts/TheDefaultLayoutWrapAppBar'


export default {
  name: 'TheLayoutWrap',
  components: {
    TheDefaultLayoutWrapAppBar,
    TheDefaultLayoutWrapMenuList
  },

  data () {
    return {
      menuItems: [
        {
          title: 'Products',
          to: '/dashboard',
          class: 'nav-item-products'
        },
        {
          title: 'Users',
          to: '/users',
          class: 'nav-item-organization'
        }
      ]
    }
  },

  methods: {
    redirectToHomePage () {
      if (this.$route.path === '/dashboard') {
        return
      }

      this.$router.push(`/dashboard`)
    }
  }
}
</script>

<style lang="scss" scoped>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.4s ease;
}


.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
