<template>
  <div>
    <h1 class="mb-6">
      Organizations
    </h1>

    <v-divider class="mt-1 mb-8" />

    <list-overview
      :can-create=false
      :data-source.sync="organizations"
      :selectable="true"
      list-type="organization"
    >
      <template #entity-card="{ entity, selectable }">
        <list-item-common
          :entity="entity"
          :selectable="selectable"
        />
      </template>
    </list-overview>
  </div>
</template>

<script>
import OrganizationsService from '@/services/оrganizations.service'
import ListItemCommon from '@/components/list/ListItemCommon'
import ListOverview from '@/components/list/ListOverview'

export default {
  name: 'OrganizationsView',
  components: {
    ListItemCommon,
    ListOverview
  },

  data () {
    return {
      isLoading: false,
      organizations: []
    }
  },

  methods: {
    async getOrganizations () {
      this.organizations = await OrganizationsService.getAllOrganizations()
    }
  },

  mounted () {
    this.getOrganizations()
  }
}
</script>
