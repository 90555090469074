<template>
  <app-dialog
    v-model="isDialogVisible"
    :persistent="isLoadingProp"
    :title-prop="'Edit Description'"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <v-text-field
        id="description-field"
        v-model="description"
        :error-messages="positionErrors"
        :placeholder="placeholderProp"
        clearable
        label="Description"
        required
        @blur="$v.description.$touch()"
      />
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            :disabled="isLoadingProp"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            :disabled="isButtonDisabled"
            :loading="isLoadingProp"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="submitForm"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import AppDialog from '@/components/app/AppDialog'

export default {
  name: 'UserDialogEditDescription',
  components: { AppDialog },

  props: {
    placeholderProp: {
      type: String,
      default: 'Add description'
    },

    value: {
      type: Boolean,
      default: false,
      required: true
    },

    isLoadingProp: {
      type: Boolean,
      default: false,
      required: true
    },

    userDescriptionProp: {
      type: String,
      default: ''
    }
  },

  validations: {
    description: {
      required
    }
  },

  data () {
    return {
      description: this.userDescriptionProp
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    isDescriptionChangedOnEdit () {
      return this.position === this.userDescriptionProp || this.description?.trim() === this.userDescriptionProp
    },

    positionErrors () {
      const errors = []

      if (!this.$v.description.$dirty) {
        return errors
      }

      this.isPositionChangedOnEdit && errors.push('Description must be different than the current description')
      !this.$v.description.required && errors.push('Must contain at least 1 character')
      return errors
    },

    isButtonDisabled () {
      return (this.isLoadingProp || this.$v.$anyError || this.isDescriptionChangedOnEdit)
    }
  },

  methods: {
    hideDialog () {
      this.resetUser()
      this.isDialogVisible = false
    },

    resetUser () {
      this.position = null
    },

    submitForm () {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      this.description = this.description.trim()
      this.$emit('changeUserDescription', this.description)
    }
  }
}
</script>

<style lang="scss" scoped>
.color-light-gray {
  color: var(--v-grey-lighten1);
}
</style>
