<template>
  <div>
    <app-single-item-left-section
      :entity-item-prop="product"
    >

      <template #other-fields>
        <div class="text-regular pt-3">
          <strong>Number of users: </strong> {{ product.users_count }}
        </div>

        <div class="text-regular pt-3">
          <strong>Last updated:  </strong> {{ $convertDate(product.updated_at, 'YYYY-MMMM-DD') }}
        </div>

        <div class="text-regular pt-3">
          <strong>Current version: </strong> to be developed
        </div>

        <div class="mt-6">
          <v-tooltip bottom class="text-center" color="primary">
            <template #activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click.native="handleOpenProductClick"
                >
                  <app-svg
                    color="primary"
                    size="24"
                    :src="`~/assets/img/icons/icon-export-${hover ? 'solid' : 'line' }.svg`"
                  />
                </v-btn>
              </v-hover>
            </template>

            <span>Open Product </span>
          </v-tooltip>
        </div>

      </template>
    </app-single-item-left-section>

    <product-dialog-open-product
      v-if="showOpenProductDialog"
      v-model="showOpenProductDialog"
      :product="product"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AppSingleItemLeftSection from '@/components/app/AppSingleItemLeftSection'
import AppSvg from '@/components/app/AppSvg'
import ProductDialogOpenProduct from '@/components/product/ProductDialogOpenProduct'
export default {
  name: "ProjectLeftSidePanel",
  components: {
    AppSingleItemLeftSection,
    AppSvg,
    ProductDialogOpenProduct
  },

  data () {
    return {
      showOpenProductDialog: false
    }
  },

  computed: {
    ...mapGetters(
      {
        product: 'product/getProduct',
      }
    )
  },

  methods: {
    handleOpenProductClick () {
      this.showOpenProductDialog = true
    }
  }
}
</script>

<style scoped lang="scss">

</style>