import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AppLayoutContent from '@/AppLayoutContent'
import AuthLoginView from '@/views/auth/AuthLoginView'
import AuthForgottenPasswordView from '@/views/auth/AuthForgottenPasswordView'
import AuthResetPasswordView from '@/views/auth/AuthResetPasswordView'
import AppAuthLayoutContent from '@/AppAuthLayoutContent'
import OrganizationsView from '@/views/OrganizationsView'

import guest from './middleware/guest'
import auth from './middleware/auth'
import store from '@/store'

import middlewarePipeline from "./middlewarePipeline"
import UsersView from '@/views/UsersView'
import UserSingleView from '@/views/user/UserSingleView'
import AuthChooseOrganizationView from '@/views/auth/AuthChooseOrganizationView'
import NoAccessOrganizationView from '@/views/NoAccessOrganizationView'
import PageNotFoundView from '@/views/PageNotFoundView'
import AuthInvitationNewUserView from '@/views/auth/AuthInvitationNewUserView'
import AuthSetPasswordSuccessView from '@/views/auth/AuthSetPasswordSuccessView'
import ProductSingleView from '@/views/product/ProductSingleView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: AppLayoutContent,
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },

      {
        path: '/dashboard',
        component: HomeView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/products/:id',
        component: ProductSingleView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/organization',
        name: 'organization',
        component: OrganizationsView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/users',
        name: 'users',
        component: UsersView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/users/:id',
        name: 'single-user',
        component: UserSingleView,
        meta: {
          middleware: [auth]
        }
      }
    ]
  },

  {
    path: '/login',
    component: AppAuthLayoutContent,
    children: [
      {
        path: '/login',
        component: AuthLoginView,
        meta: {
          middleware: [guest]
        }
      },

      {
        path: '/choose-organization',
        component: AuthChooseOrganizationView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/forgotten-password',
        component: AuthForgottenPasswordView
      },

      {
        path: '/invitation/:id',
        component: AuthInvitationNewUserView,
        meta: {
          middleware: [guest]
        }
      },

      {
        path: '/reset-password/:passwordToken',
        component: AuthResetPasswordView,
        meta: {
          middleware: [guest]
        }
      },

      {
        path: '/set-password-success',
        component: AuthSetPasswordSuccessView,
        meta: {
          middleware: [guest]
        }
      }
    ]
  },

  {
    path: '/no-access-organization',
    name: 'no-access-organization',
    component: NoAccessOrganizationView,
    meta: {
      middleware: [auth]
    }
  },

  { path: '/404', component: PageNotFoundView },
  { path: '*', redirect: '/404' }
]

export const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }

  return middleware[0]({ ...context, nextMiddleware: middlewarePipeline(context, middleware, 1) })
})

export default router
