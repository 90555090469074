<template>
  <app-dialog
    v-model="isDialogVisible"
    title="Add Users"
    max-width="1200"
    :titleProp="'Add Users'"
    v-bind="$attrs"
    v-on="$listeners"
  >

      <app-loader-listing v-if="isLoading">

      </app-loader-listing>

    <div v-else>
      <product-users-expansion-panel
        v-if="availableProductUsers.length"
        :users-grouped-by-role="availableProductUsers"
      />
      <div v-else>
        No available users to attach
      </div>

    </div>

    <template #actions>
      <div class="d-flex justify-end" style="width: 100%">
        <v-btn
          color="primary"
          raised
          rounded
          large
          min-width="150"
          :disabled="!selectedUsers.length"
          @click="confirmClick"
        >
          Confirm
        </v-btn>
      </div>
    </template>
  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'
import ProductUsersExpansionPanel from '@/components/product/tabs/user/ProductUsersExpansionPanel'
import AppLoaderListing from '@/components/app/AppLoaderListing'

import ProductService from '@/services/product.service'
import { ProductUsersClass } from '@/classes/product/ProductUsersClass'

import { mapGetters } from 'vuex'

export default {
  name: "ProductDialogAddUsers",
  components: {
    AppDialog,
    ProductUsersExpansionPanel,
    AppLoaderListing
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  data () {
    return {
      isLoading: true,
      availableProductUsers: []
    }
  },

  computed: {
    ...mapGetters(
      {
        selectedUsers: 'product/getSelectedUsers'
      }
    ),

    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    async confirmClick () {
      this.$emit('input', false)
      const payload = {
        product_id: this.$route.params.id,
        user_ids: this.selectedUsers.map(item => item.id)
      }
      await this.$store.dispatch('product/attachUsersToProduct', payload)
    }
  },

  async created () {
    try {
      const availableProductUsersResponse = await ProductService.getProductAvailableUsers(this.$route.params.id)
      this.availableProductUsers = new ProductUsersClass(availableProductUsersResponse)
    } catch (e) {
      this.$router.replace('/dashboard')
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style scoped>

</style>