<template>
  <app-dialog
    v-model="isDialogVisible"
    title="Change Password"
    max-width="500"
    :persistent="isLoading"
    v-bind="$attrs"
    v-on="$listeners"
    :titleProp="'Change Password'"
  >
    <p>
      Change your password by following the instruction below. If you don’t remember your old password,
      you can reset it using the button <span style="font-weight: bold;">Forgotten Password</span> bellow.
    </p>
    <a
      id="forgotten-password-link"
      @click="redirectToForgottenPassword"
    >
      Forgotten Password
    </a>

    <v-text-field
      id="old-password-field"
      v-model="oldPassword"
      label="Old password"
      placeholder="Old password"
      :error-messages="oldPasswordErrors"
      clearable
      :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showOldPassword ? 'text' : 'password'"
      autocomplete="off"
      lazy-validation
      counter
      @click:append="showOldPassword = !showOldPassword"
      @input="$v.oldPassword.$touch()"
      @blur="$v.oldPassword.$touch()"

    />
    <v-text-field
      id="new-password-field"
      v-model="newPassword"
      label="New password"
      placeholder="New password"
      :error-messages="newPasswordErrors"
      clearable
      :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showNewPassword ? 'text' : 'password'"
      autocomplete="off"
      lazy-validation
      counter
      @click:append="showNewPassword = !showNewPassword"
      @input="$v.newPassword.$touch()"
      @blur="$v.newPassword.$touch()"
    />
    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            rounded
            raised
            block
            x-large
            outlined
            color="primary"
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            rounded
            raised
            block
            x-large
            color="primary"
            @click="clickConfirmRenewPassword"
            :loading="loading"
            :disabled="$v.$anyError || (!oldPassword && !newPassword)"
          >
            Confirm
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { helpers, required } from 'vuelidate/lib/validators'
import { invalidNewPassErrMSg, validPassRegex } from '@/validations/passwordValidations'
import AppDialog from '@/components/app/AppDialog'

export default {
  mixins: [validationMixin],
  name: 'UserDialogChangePassword',
  components: { AppDialog },

  data () {
    return {
      oldPassword: null,
      newPassword: null,
      showOldPassword: false,
      showNewPassword: false,
      loading: false
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  validations: {
    oldPassword: {
      required
    },
    newPassword: {
      required,
      /* eslint-disable */
      validPass: helpers.regex('valid chars', validPassRegex)
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    isLoading () {
      return this.loading
    },

    oldPasswordErrors () {
      const errors = []
      if (!this.$v.oldPassword.$dirty) {
        return errors
      }
      !this.$v.oldPassword.required && errors.push('Old password is required')
      return errors
    },

    newPasswordErrors () {
      const errors = []
      if (!this.$v.newPassword.$dirty) {
        return errors
      }
      !this.$v.newPassword.required && errors.push('New password is required')
      !this.$v.newPassword.validPass && errors.push(invalidNewPassErrMSg)
      return errors
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    async clickConfirmRenewPassword () {

      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }
      try {
        this.loading = true

        const payload = { old_password: this.oldPassword, new_password: this.newPassword }

        await this.$store.dispatch('auth/changePassword', payload)

        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Your password was changed successfully!',
          duration: 4000,
          mode: 'success'
        })
      } catch (e) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: e.response.data.message,
          duration: 4000,
          mode: 'fail'
        })
      } finally {
        this.loading = false
        this.hideDialog()
      }
    },

    async redirectToForgottenPassword () {
      await this.$store.dispatch('auth/signOut')
      this.$router.replace('/forgotten-password')
    }
  }
}
</script>

<style scoped>

</style>
