import axios from 'axios'
import VueCookies from 'vue-cookies'
import store from '../store'
import router from '../router'

let $axios = axios.create({
  baseURL: `${ window.location.protocol }//${ process.env.VUE_APP_API_OMT_BASE_URL }/api/v1`,
  withCredentials: false
})

$axios.interceptors.request.use(
  (config) => {
    let cookie = VueCookies.get('current_user')

    if (cookie) {
      config.headers.Authorization = `Bearer ${ VueCookies.get('current_user') }`
    }
    // config.headers.Cookie = `current_user=${Cookie.get('current_user')}`;
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

$axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    let errorMessage

    switch (error.response.status) {
      case 500:
        errorMessage = error.response.data.message + " | ref: " + error.response.data.ref
        break
      default:
        if (error.response.data?.message) {
          errorMessage = error.response.data.message
        }
        break
    }

    store.dispatch('snackbar/showSnackbarMessage', {
      message: errorMessage,
      duration: 4000,
      mode: 'fail'
    })

    // if (404 === error.response.status) {
    //   router.push('/404')
    // }

    if (401 === error.response.status) {
      VueCookies.remove('current_user')

      if (router.currentRoute.path !== '/login') {
        window.location.href = `${ window.location.protocol }//${ process.env.VUE_APP_OMT_FRONT_END_BASE_URL }:${ window.location.port }/login`
      }
    }

    return Promise.reject(error)
  }
)

export default $axios
