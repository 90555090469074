import { render, staticRenderFns } from "./ProductDialogOpenProduct.vue?vue&type=template&id=2d311aa8&scoped=true&"
import script from "./ProductDialogOpenProduct.vue?vue&type=script&lang=js&"
export * from "./ProductDialogOpenProduct.vue?vue&type=script&lang=js&"
import style0 from "./ProductDialogOpenProduct.vue?vue&type=style&index=0&id=2d311aa8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d311aa8",
  null
  
)

export default component.exports