<template>
  <div>
    <app-subpage-header
      :back-link-prop="'/dashboard'"
      :title-prop="'Products'"
    />

    <app-loader-content
      v-if="currentState === actionStateConstants.LOADING"
    />

    <product-single-overview v-else />

  </div>
</template>

<script>
import AppSubpageHeader from '@/components/app/AppSubpageHeader'
import ProductSingleOverview from '@/components/product/ProductSingleOverview'
import AppLoaderContent from '@/components/app/AppLoaderContent'
import { actionStates } from '@/store/modules/product'

import { mapGetters } from 'vuex'

export default {
  name: "ProductSingleView",
  components: {
    AppSubpageHeader,
    ProductSingleOverview,
    AppLoaderContent
  },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        product: 'product/getProduct',
        currentState: 'product/getCurrentState'
      }
    )
  },

  async created () {
    await this.$store.dispatch('product/fetchProduct', this.$route.params.id)
  }

}
</script>

<style scoped>

</style>