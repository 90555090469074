import $axios from '../plugins/axios'

class ProductService {
  getAllProducts () {
    return $axios.get('/get-products')
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProduct (productId) {
    return $axios.get(`get-product/${productId}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
  
  getUserProducts (id) {
    return $axios.get(`/get-user-products/${id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getOrganizationProducts () {
    return $axios.get(`/get-organization-products`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProductUsers (productId) {
    return $axios.get(`/get-product-users/${productId}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProductAvailableUsers (productId) {
    return $axios.get(`/get-product-available-users/${productId}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  attachUsersToProduct (data) {
    return $axios.post('/attach-users-to-product', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  detachUsersFromProduct (data) {
    return $axios.post('/detach-users-from-product', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProductVersions (productId) {
    return $axios.get(`/get-product-versions/${productId}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  createProductVersion (data) {
    return $axios.post('/create-product-version', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  updateProductVersion (data) {
    return $axios.post('/update-product-version', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new ProductService()
