<template>
  <v-container class="pa-10">
    <v-row>
      <v-col class="text-center">
        <span class="grey--text">Welcome! Please create a new password for your account</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <form @submit.prevent="submitConfirmPassword">
          <v-text-field
            id="email"
            v-model="userEmail"
            class="mt-8"
            disabled
            type="text"
          />

          <v-text-field
            id="password"
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :error-messages="passwordErrors"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="off"
            class="my-8"
            hint="Enter your password"
            label="Password"
            name="input-password"
            @blur="$v.password.$touch()"
            @click:append="showPassword = !showPassword"
          />

          <v-text-field
            id="confirm-password"
            v-model="confirmPassword"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :error-messages="confirmPasswordErrors"
            :type="showConfirmPassword ? 'text' : 'password'"
            autocomplete="off"
            label="Confirm password"
            name="input-confirm-password"
            @blur="$v.confirmPassword.$touch()"
            @click:append="showConfirmPassword = !showConfirmPassword"
          />

          <v-row v-if="errors.length" class="my-0">
            <v-col
              id="error-other"
              class="text-center text-error pa-1"
              cols="12"
            >
              An error occurred! Please check and try again.
            </v-col>
          </v-row>

          <v-row class="mt-15">
            <v-col class="text-center">
              <v-btn
                id="login-btn"
                :disabled="$v.$anyError"
                :loading="isButtonLoading"
                color="primary"
                raised
                rounded
                type="submit"
                width="200"
                x-large
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { minLength, required, sameAs } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'AuthInvitationNewUser',

  validations: {
    password: {
      required,
      minLength: minLength(8)
    },

    confirmPassword: {
      sameAsPassword: sameAs('password')
    }
  },

  data () {
    return {
      password: null,
      errors: [],
      userEmail: null,

      isButtonLoading: false,
      showPassword: false,

      confirmPassword: null,
      showConfirmPassword: false
    }
  },

  computed: {
    passwordErrors () {
      const errors = []

      if (!this.$v.password.$dirty) {
        return errors
      }

      !this.$v.password.required && errors.push('Password is required')
      !this.$v.password.minLength && errors.push('Password must be at least 8 symbols')
      return errors
    },

    confirmPasswordErrors () {
      const errors = []

      if (!this.$v.confirmPassword.$dirty) {
        return errors
      }

      !this.$v.confirmPassword.sameAsPassword && errors.push('Passwords must match')
      return errors
    }
  },

  methods: {
    async submitConfirmPassword () {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      this.isButtonLoading = true
      try {
        await this.$store.dispatch('auth/createUserFromInvitation', {
          id: uuidv4(),
          invitation_id: this.$route.params.id,
          password: this.password
        })
        await this.$router.push('/set-password-success')
      } finally {
        this.isButtonLoading = false
      }
    }
  },

  async beforeCreate () {
    try {
      let response = await this.$store.dispatch('user/checkInvitationExists', { id: this.$route.params.id })

      if (response.user_exists === true) {
        this.$router.push('/login')
      }

      this.userEmail = response.email ?? ''
    } catch (error) {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 80%;
}

:deep(.theme--light.v-text-field.v-input--is-disabled .v-input__slot::before) {
  border-image: unset !important;
}
</style>
