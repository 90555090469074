import { render, staticRenderFns } from "./UserListOverview.vue?vue&type=template&id=ecc61fd2&scoped=true&"
import script from "./UserListOverview.vue?vue&type=script&lang=js&"
export * from "./UserListOverview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecc61fd2",
  null
  
)

export default component.exports