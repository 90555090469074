<template>
  <app-dialog
    v-model="isDialogVisible"
    title="Edit Version"
    max-width="800"
    :titleProp="'Edit Version'"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <v-text-field
        v-model="version"
        hint="This field uses counter prop"
        label="Version Number"
        :error-messages="versionErrors"
        @blur="$v.$touch()"
        @input="$v.version.$touch()"
      >
      </v-text-field>

      <v-textarea
        class="mt-6"
        name="input-7-1"
        label="Version Log"
        auto-grow
        v-model="log"
        :error-messages="logErrors"
        @blur="$v.$touch()"
        @input="$v.log.$touch()"
      >
      </v-textarea>

    </div>

    <template #actions>
      <div class="d-flex justify-end" style=" width: 100%">
        <v-btn
          id="close-dialog-button"
          color="primary"
          outlined
          raised
          rounded
          large
          min-width="150"
          @click.prevent="$emit('input', false)"
        >
          Cancel
        </v-btn>

        <v-btn
          id="submit-button"
          color="primary"
          raised
          rounded
          large
          min-width="150"
          class="ml-4"
          @click="updateVersion"
          :loading="loading"
          :disabled="loading || $v.$anyError"
        >
          Confirm
        </v-btn>
      </div>

    </template>

  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'
import ProductService from '@/services/product.service'
import { maxLength, required } from 'vuelidate/lib/validators'


export default {
  name: "ProductDialogEditVersion",
  components: { AppDialog },

  validations: {
    version: {
      required,
      maxLength: maxLength(255)
    },

    log: {
      required
    }
  },

  data () {
    return {
      version: null,
      log: null,
      versionId: null,
      loading: false
    }
  },

  props: {
    versionProp: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    versionErrors () {
      const errors = []
      if (!this.$v.version.$dirty) {
        return errors
      }

      !this.$v.version.maxLength && errors.push('Can contain maximum of 255 characters')
      !this.$v.version.required && errors.push('Must contain at least 1 character')
      return errors
    },

    logErrors () {
      const errors = []
      if (!this.$v.version.$dirty) {
        return errors
      }

      !this.$v.log.required && errors.push('Must contain at least 1 character')
      return errors
    }
  },

  methods: {
    async updateVersion () {
      const payload  = {
        id: this.versionId,
        version: this.version,
        log: this.log
      }
      try {
        this.loading = true
        await ProductService.updateProductVersion(payload)
      } finally {
        this.loading = false
        this.$emit('input', false)
        this.$emit('refresh')
      }
    }
  },

  created () {
    this.version = this.versionProp.version
    this.log = this.versionProp.log
    this.versionId = this.versionProp.id
  }
}
</script>

<style scoped>

</style>