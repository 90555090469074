<template>
  <app-dialog
    v-model="isDialogVisible"
    title="Open Product"
    max-width="500"
    :titleProp="'Open Product'"
    v-bind="$attrs"
    v-on="$listeners"
  >

    <div class="d-flex justify-space-between">
      <div>
        {{ product.name }}
      </div>

      <v-btn
        color="primary"
        raised
        rounded
        small
        @click.native="openInNewTab(`https://${selectedOrganization.slug}.${product.domain}`)"
      >
        Visit Website
      </v-btn>
    </div>


    <div class="d-flex mt-6 justify-space-between align-center">

      <div class="custom-text-field px-3 py-1">
        {{ `https://${selectedOrganization.slug}.${product.domain}` }}
      </div>

      <v-btn
        color="primary"
        outlined
        raised
        rounded
        small
        @click="copy"
      >
        Copy Link
      </v-btn>
    </div>

  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'

import { mapGetters } from 'vuex'

export default {
  name: "ProductDialogOpenProduct",
  components: { AppDialog },
  props: {
    product: {
      type: Object,
      required: true
    },

    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  computed: {
    ...mapGetters(
      {
        selectedOrganization: 'user/getUserSelectedOrganization'
      }
    ),

    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    openInNewTab (link) {
      window.open(link)
    },

    async copy () {
      await navigator.clipboard.writeText(`https://${this.selectedOrganization.slug}.${this.product.domain}`)
    }
  }

}
</script>

<style scoped lang="scss">
.custom-text-field {
  border-bottom: 1px solid;
  min-width: 350px;
  &:hover {
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);
    background-color: var(--v-primary-lighten2)
  }
}

</style>