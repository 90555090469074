<template>
  <app-dialog
    v-model="isDialogVisible"
    :persistent="isLoadingProp"
    :title-prop="'Edit position'"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <v-text-field
        id="position-field"
        v-model="position"
        :error-messages="positionErrors"
        :placeholder="placeholderProp"
        clearable
        label="Position"
        required
        @blur="$v.position.$touch()"
      />
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            :disabled="isLoadingProp"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            :disabled="isButtonDisabled"
            :loading="isLoadingProp"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="submitForm"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import AppDialog from '@/components/app/AppDialog'

export default {
  name: 'UserDialogEditPosition',
  components: { AppDialog },

  props: {
    placeholderProp: {
      type: String,
      default: 'Add position'
    },

    value: {
      type: Boolean,
      default: false,
      required: true
    },

    isLoadingProp: {
      type: Boolean,
      default: false,
      required: true
    },

    userPositionProp: {
      type: String,
      default: ''
    }
  },

  validations: {
    position: {
      required,
      maxLength: maxLength(255)
    }
  },

  data () {
    return {
      position: this.userPositionProp
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    isPositionChangedOnEdit () {
      return this.position === this.userPositionProp || this.position?.trim() === this.userPositionProp
    },

    positionErrors () {
      const errors = []

      if (!this.$v.position.$dirty) {
        return errors
      }

      this.isPositionChangedOnEdit && errors.push('Position should be different than the current position')
      !this.$v.position.maxLength && errors.push('Can contain maximum of 255 characters')
      !this.$v.position.required && errors.push('Must contain at least 1 character')
      return errors
    },

    isButtonDisabled () {
      return (this.isLoadingProp || this.$v.$anyError || this.isPositionChangedOnEdit)
    }
  },

  methods: {
    hideDialog () {
      this.resetUser()
      this.isDialogVisible = false
    },

    resetUser () {
      this.position = null
    },

    submitForm () {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      this.position = this.position.trim()
      this.$emit('changeUserPosition', this.position)
    }
  }
}
</script>

<style lang="scss" scoped>
.color-light-gray {
  color: var(--v-grey-lighten1);
}
</style>
