import { render, staticRenderFns } from "./UserListItemCommonText.vue?vue&type=template&id=bba2a540&scoped=true&"
import script from "./UserListItemCommonText.vue?vue&type=script&lang=js&"
export * from "./UserListItemCommonText.vue?vue&type=script&lang=js&"
import style0 from "./UserListItemCommonText.vue?vue&type=style&index=0&id=bba2a540&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bba2a540",
  null
  
)

export default component.exports