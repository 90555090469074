<template>
  <v-container class="py-8">
    <v-row>
      <v-col class="text-center">
        <span class="grey--text">Set new password</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :disabled="isLoading"
              :error-messages="passwordErrors"
              :hint="hintPassword"
              :type="showPassword ? 'text' : 'password'"
              autocomplete="off"
              counter
              label="Password"
              lazy-validation
              name="input-user-password"
              @blur="$v.password.$touch()"
              @click:append="showPassword = !showPassword"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="passwordConfirm"
              :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              :disabled="isLoading"
              :error-messages="passwordConfirmErrors"
              :hint="hintConfirmPassword"
              :type="showPasswordConfirm ? 'text' : 'password'"
              autocomplete="off"
              counter
              label="Confirm Password"
              lazy-validation
              name="input-password-confirm"
              @blur="$v.passwordConfirm.$touch()"
              @click:append="showPasswordConfirm = !showPasswordConfirm"
            />
          </v-col>
        </v-row>

        <v-row class="pt-6">
          <v-col class="text-center">
            <v-btn
              :disabled="isSubmitButtonDisabled || $v.$anyError"
              :loading="isLoading"
              color="primary"
              min-width="200"
              raised
              rounded
              x-large
              @click="confirmAndProceed"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center">
            <p>
              Already have an account?
              <a @click="$router.push('/login')">
                Log in
              </a>
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { helpers, required, sameAs } from 'vuelidate/lib/validators'
import {
  confirmPassErrMsg,
  hintConfirmPassword,
  passInvalidErrMsg,
  passMismatchErrMsg,
  validPassRegex
} from '@/validations/passwordValidations'
import { validationMixin } from 'vuelidate'

export default {
  name: 'AuthResetPasswordView',
  auth: 'guest',
  mixins: [validationMixin],
  data () {
    return {
      password: '',
      passwordConfirm: '',
      showPassword: false,
      showPasswordConfirm: false,
      loading: false,
      hintPassword: passInvalidErrMsg,
      hintConfirmPassword: hintConfirmPassword
    }
  },
  validations: {
    password: {
      required,
      validPass: helpers.regex('valid chars', validPassRegex)
    },
    passwordConfirm: {
      required,
      validPass: helpers.regex('valid chars', validPassRegex),
      sameAsPassword: sameAs('password')
    }
  },
  computed: {
    isLoading () {
      return this.loading
    },

    passwordErrors () {
      const errors = []
      if (!this.$v.$anyError) {
        return errors
      }
      !this.$v.password.validPass && errors.push(passInvalidErrMsg)

      return errors
    },
    passwordConfirmErrors () {
      const errors = []
      if (!this.$v.$anyError) {
        return errors
      }
      !this.$v.passwordConfirm.required && errors.push(confirmPassErrMsg)
      !this.$v.passwordConfirm.sameAsPassword && errors.push(passMismatchErrMsg)

      return errors
    },

    isSubmitButtonDisabled () {
      return !this.isLoading && this.$v.$anyError
    }
  },
  methods: {
    async confirmAndProceed () {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      try {
        this.loading = true

        await this.$store.dispatch('auth/resetPassword', {
          token: this.$route.params.passwordToken,
          email: this.$route.query.email,
          password: this.password
        })

        this.$router.replace('/set-password-success')
      } catch (e) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: e.response.data.message,
          duration: 4000,
          mode: 'fail'
        })
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    if (!this.$route.params.passwordToken || !this.$route.query.email) {
      this.$router.replace('/forgotten-password')
    }
  },
  destroyed () {
    this.password = ''
    this.passwordConfirm = ''
  }
}
</script>

<style scoped>

</style>
