<template>
  <div class="py-3">
    <v-expansion-panels
      v-for="(userRole, index) in userRoles"
      :key="`configuration-${userRole}-${index}`"
      :value="userRole.expValue"
      accordion
      flat
      focusable
      multiple
      hover
      tile
      class="ma-0"
    >

      <app-expansion-panel
        fit
        class="small-expansion-panel"
        :class="areAllUserFromRoleSelected(userRole) ? 'selected' : ''"
      >
        <template #header>
          <div>
            <app-list-item
              :selected="areAllUserFromRoleSelected(userRole)"
              :indeterminate="userRole.indeterminate"
              :change-color-select="false"
              @update:selected="onRolesSelect($event, userRole)"
            >
              <template #title>
                <h5 class="text-h5 py-0">
                  {{ userRole.name }} ({{ usersGroupedByRole[userRole.name].length }})
                </h5>
              </template>
            </app-list-item>
          </div>

        </template>

        <div>
          <div
            v-for="user in usersGroupedByRole[`${userRole.name}`]"
            :key="user.id"
          >
            <app-list-item
              :selected="selectedUsers.includes(user)"
              @update:selected="onUserSelect($event, userRole,  user)"
            >
              <v-avatar class="image-border px-1" rounded size="60">
                <v-img
                  :alt="`${user.name} picture`"
                  :src="user.image && user.image.url"
                />
              </v-avatar>

              <template #title>
                <span>{{ user.name }} </span>
              </template>

              <template #subtitle>
                <span>{{ user.email }} </span>
              </template>

              <template #actions v-if="hasInlineDeleteAction">
                <div v-if="selectedUsers.includes(user)">
                  <v-btn
                    icon
                    small
                    @click="deleteClick"
                  >
                    <app-svg
                      size="16"
                      src="~/assets/img/icons/delete.svg"
                    />
                  </v-btn>
                </div>
              </template>

            </app-list-item>
          </div>

        </div>

      </app-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import AppExpansionPanel from '@/components/app/AppExpansionPanel'
import AppListItem from '@/components/app/AppListItem'
import AppSvg from '@/components/app/AppSvg'

import { mapGetters } from 'vuex'

export default {
  name: "ProductUsersExpansionPanel",
  components: {
    AppSvg,
    AppExpansionPanel,
    AppListItem
  },

  props: {
    usersGroupedByRole: {
      type: Object,
      required: true
    },

    hasInlineDeleteAction: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      expandedPanels: [],
      userRoles: [],
      rerender: 0,
      currentUserRole: null,
    }
  },

  computed: {
    ...mapGetters(
      {
        selectedUsers: 'product/getSelectedUsers'
      }
    )
  },

  watch: {
    selectedUsers () {
      if (this.currentUserRole) {
        this.setUserRoleIndeterminate(this.currentUserRole)
      }
    }
  },

  methods: {
    deleteClick () {
      this.$emit('showUserDetachDialog')
    },

    openCloseExpandedPanel (isCheckboxSelected, userRole) {
      if (isCheckboxSelected) {
        userRole.expValue = [0]
      } else {
        userRole.expValue = [-1]
      }
    },

    onRolesSelect (isCheckboxSelected, userRole) {
      this.currentUserRole = userRole

      const usersPerRole = this.usersGroupedByRole[`${userRole.name}`]

      for (const user of  usersPerRole) {
        this.onUserSelect(isCheckboxSelected, userRole,  user)
      }

      this.openCloseExpandedPanel(isCheckboxSelected, userRole)
    },

    areAllUserFromRoleSelected (userRole) {
      const usersPerRole = this.usersGroupedByRole[`${userRole.name}`]
      return usersPerRole.every(item => this.selectedUsers.includes(item))
    },

    setUserRoleIndeterminate (userRole) {
      const usersPerRole = this.usersGroupedByRole[`${userRole.name}`]
      const selectedUserPerRole = usersPerRole.filter(item => this.selectedUsers.some(obj => obj.id === item.id))

      if (selectedUserPerRole.length  === 0) {
        userRole.indeterminate = false
      } else if (usersPerRole.length > selectedUserPerRole.length) {
        userRole.indeterminate = true
      } else if (usersPerRole.length === selectedUserPerRole.length) {
        userRole.indeterminate = false
      }
    },

    onUserSelect (isCheckboxSelected, userRole,  user) {
      this.currentUserRole = userRole

      if (isCheckboxSelected) {
        this.$store.dispatch('product/addUserToSelectedUsers', user)
      } else {
        this.$store.dispatch('product/removeUserFromSelectedUsers', user)
      }
    }
  },

  mounted () {
    this.userRoles = this.usersGroupedByRole.rolesForExPan()
  },

  beforeDestroy () {
    this.$store.dispatch('product/modifySelectedUsers', [])
  }
}
</script>

<style scoped>

</style>