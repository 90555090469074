<template>
  <app-dialog
    v-model="isDialogVisible"
    title="Detach Users"
    max-width="800"
    :titleProp="'Detach Users'"
    v-bind="$attrs"
    v-on="$listeners"
  >

    <div class="text-regular">

      <div v-if="selectedUsers.length === 1" >
        Do you want to detach this user:
      </div>

      <div v-else>
        Do you want to detach these users:
      </div>


      <strong>
        {{ selectedUsers.map(item => item.email).join(', ') }}
      </strong>
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            block
            color="primary"
            outlined
            raised
            rounded
            large
            @click.prevent="$emit('input', false)"
          >
            No
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            block
            color="primary"
            raised
            rounded
            large
            @click.native="yesClick"
          >
            <span class="text-capitalize">
              Yes
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>



  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'

import { mapGetters } from 'vuex'

export default {
  name: "ProductDialogRemoveUsers",
  components: {
    AppDialog
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  computed: {
    ...mapGetters(
      {
        selectedUsers: 'product/getSelectedUsers'
      }
    ),

    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    yesClick () {
      this.$emit('input', false)
      this.$emit('detachUsers')
    }
  }
}
</script>

<style scoped>

</style>