<template>
  <v-card
    :class="!entity.enabled ? 'deactivated-user' : ''"
    :ripple="{ class: 'primary--text' }"
    :to="target"
    class="v-card--bordered"
  >
    <v-img
      v-if="entity.image && entity.image.url"
      :src="entity.image && entity.image.url"
      contain
      height="150"
    />

    <v-img
      v-else
      :src="emptyUserImage"
    />

    <v-divider />

    <div class="card-content">
      <v-card-title>
        <user-list-item-common-text
          v-if="entity.name"
          :text-prop="entity.name"
        />

        <div style="width: 100%" />

        <user-list-item-common-text
          v-if="entity.email"
          :text-class-prop="'text-small grey--text'"
          :text-prop="entity.email"
        />

        <div style="width: 100%;">
          <slot name="under-the-name" />
        </div>
      </v-card-title>

      <v-spacer />
    </div>
  </v-card>
</template>

<script>
import UserListItemCommonText from '@/components/user/UserListItemCommonText'

export default {
  name: "UserListItemCommon",
  components: { UserListItemCommonText },
  props: {
    entity: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false
    },
    target: {
      type: String,
      required: false
    }
  },

  computed: {
    emptyUserImage () {
      return require('@/assets/img/generic-user.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.card-content {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px 20px;

  & > * {
    min-width: 0;
    margin-left: 5px;
    flex-shrink: 0;

    &:first-child {
      margin-left: 0;
    }
  }

  .v-card__title {
    padding: 0;
    flex-shrink: 1;
  }
}

.v-card {
  .select-checkbox {
    opacity: 0;
    visibility: hidden;
    transform: translateX(5px);
    transition: transform $secondary-transition,
    opacity $secondary-transition,
    visibility $secondary-transition;
  }

  &:hover,
  &.selected {
    .select-checkbox {
      opacity: 1;
      visibility: visible;
      transform: translateX(0px);
    }
  }
}

</style>
