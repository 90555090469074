<template>
  <div class="content-wrapper pa-4" style="position: relative" ref="singleOverviewContainer">

    <v-overlay
      class="custom-overlay"
      :value="!user.enabled "
      :attach="$refs.singleOverviewContainer"
      absolute
      color="var(--v-white-base)"
      opacity="0.6"

    >
    </v-overlay>

      <v-layout class="flex-column flex-md-row">
          <user-edit-left-side-panel-container />

          <user-edit-right-side-panel-container />
      </v-layout>
  </div>
</template>

<script>
import UserEditLeftSidePanelContainer from '@/components/user/edit/UserEditLeftSidePanelContainer'
import UserEditRightSidePanelContainer from '@/components/user/edit/UserEditRightSidePanelContainer'

import { mapGetters } from 'vuex'

export default {
  name: 'UserSingleOverview',
  components: {
    UserEditRightSidePanelContainer,
    UserEditLeftSidePanelContainer
  },

  computed: {
    ...mapGetters(
      {
        user: 'user/getUser',
      }
    ),
  }

}
</script>

<style lang="scss" scoped>
.field-editable-text {
  color: var(--v-grey-base);
}
</style>
