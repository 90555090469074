<template>
  <div class="mx-auto">
    <div class="image-component">
      <v-img
        v-if="imageProp"
        :class="imageCompOptions.class ? imageCompOptions.class : ''"
        :contain="imageCompOptions.contain"
        :cover="imageCompOptions.cover"
        :height="imageCompOptions.height"
        :max-height="imageCompOptions.maxHeight"
        :max-width="imageCompOptions.maxWidth"
        :src="imageProp.url"
        :width="imageCompOptions.width"
      >
        <div class="selector-container">
          <v-tooltip bottom class="text-center" color="primary">
            <template #activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  :color="hover ? 'primary' : 'white'"
                  fab
                  small
                  v-bind="attrs"
                  @click="showEditImageDialog"
                  v-on="on"
                >
                  <app-svg
                    :color="hover ? 'white' : 'var(--v-primary-base)'"
                    size="16"
                    src="~/assets/img/icons/camera.svg"
                  />
                </v-btn>
              </v-hover>
            </template>

            <span>Update image. </span>
            <span>Supported formats: png, jpeg, jpg</span>
          </v-tooltip>
        </div>
      </v-img>

      <div v-else-if="!imageProp">
        <v-card
          :class="imageCompOptions.class ? imageCompOptions.class : ''"
          :contain="imageCompOptions.contain"
          :cover="imageCompOptions.cover"
          :height="imageCompOptions.height"
          :max-height="imageCompOptions.maxHeight"
          :max-width="imageCompOptions.maxWidth"
          :width="imageCompOptions.width"
          class="empty-image-card d-flex justify-center align-center pa-6 flex-column"
          @click="$emit('showEditImageDialog')"
        >
          <v-avatar size="80">
            <div class="icon">
              <app-svg
                :src="'~/assets/img/icons/camera.svg'"
                alt="icon"
                color="white"
                size="36"
              />
            </div>
          </v-avatar>

          <div class="text-center color-grey mt-5">
            No image available. <br>
            Click here to upload your image.
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import AppSvg from '@/components/app/AppSvg'

export default {
  name: 'AppImageHolder',
  components: { AppSvg },

  props: {
    imgCoverProp: {
      type: Boolean,
      required: true
    },

    imageProp: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      imageCompOptions: {
        width: 300,
        height: 300,
        cover: this.imgCoverProp,
        contain: !this.imgCoverProp,
        class: 'image-border-radius-overview'
      },
      isUpdateImageDialogVisible: false
    }
  },

  methods: {
    showEditImageDialog () {
      this.$emit('showEditImageDialog')
    }
  }
}
</script>

<style lang="scss" scoped>
.loader-container {
  width: 300px;
  height: 300px;
  padding: 50px;
}

.selector-container {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.empty-image-card {
  background: var(--v-grey-lighten2);

  .v-avatar {
    background: var(--v-grey-lighten1);
    transition: background $secondary-transition;
  }

  &:hover {
    cursor: pointer;
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);

    .v-avatar {
      background: var(--v-primary-base);
    }
  }
}
</style>
