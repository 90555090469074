export class BaseClass {

  static createList (data) {
    if (!data) {
      return []
    }

    if (!Array.isArray(data)) {
      throw new TypeError('Error: method expects an array!')
    }

    return data.map((item) => {
      return new this(item || {})
    })
  }
}