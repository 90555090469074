<template>
  <div>
    <list-overview
      :can-create=true
      :data-source="usersProp"
      :is-list-loading-prop="isLoadingProp"
      :selectable="true"
      list-type="users"
      target-url="/users"
    >
      <template #create-card>
        <entity-card
          action="Create a new"
          entity-type="user"
          @click.native="$emit('openCreateUserDialog')"
        />
      </template>

      <template #entity-card="{ entity, selectable, target }">
        <user-list-item-common
          :entity="entity"
          :selectable="selectable"
          :target="target"
        >
          <template #under-the-name>
            <span class="text-small grey--text text-transform-capitalize">{{ entity.roles[0].name }}</span>
            <span v-if="entity.pending" class="text-small grey--text">&nbsp;(pending)</span>
          </template>
        </user-list-item-common>
      </template>
    </list-overview>
  </div>
</template>

<script>
import ListOverview from '@/components/list/ListOverview'
import EntityCard from '@/components/entity/EntityCard'
import UserListItemCommon from '@/components/user/UserListItemCommon'

export default {
  name: "UserListOverview",
  components: {
    UserListItemCommon,
    ListOverview,
    EntityCard
  },

  props: {
    usersProp: {
      type: Array,
      required: true
    },
    isLoadingProp: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
