<template>
  <div>
    <the-default-layout-wrap-app-bar
      :show-avatar-prop="false"
      @redirectToHomePage="goToHome"
    />

    <v-main class="d-flex justify-center align-center pa-0" style="height: 100vh">
      <v-container :key="$router.currentRoute.path" fluid>
        <div class="d-flex justify-center align-center flex-column">
          <v-img
            :src="getNoAccessImage"
            contain
            max-height="114"
          />
          <h4 class="mt-10 mb-6 primary--text font-weight-bold">Ops! That’s an error</h4>
          <h5>The page you are looking for does not exist </h5>
          <h5>or another error occurred</h5>

          <v-btn
            id="home-button"
            color="primary"
            raised
            rounded
            style="margin-top: 80px"
            width="200px"
            x-large
            @click.prevent="goToHome"
          >
            Home
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import TheDefaultLayoutWrapAppBar from '@/layouts/TheDefaultLayoutWrapAppBar'
import { mapGetters } from 'vuex'

export default {
  name: "PageNotFoundView",
  components: { TheDefaultLayoutWrapAppBar },

  computed: {
    getNoAccessImage () {
      return require('@/assets/img/404.png')
    },

    ...mapGetters(
      {
        organizations: 'user/getUserOrganizations'
      }
    )
  },

  methods: {
    async getAllOrganizations () {
      await this.$store.dispatch('user/fetchUserOrganizations')
    },

    async goToHome () {
      await this.getAllOrganizations()

      let currentSlug = window.location.href.substring(
        window.location.href.indexOf("//") + 2,
        window.location.href.indexOf(`.${ process.env.VUE_APP_OMT_FRONT_END_BASE_URL }`)
      )

      if (this.organizations.some(organization => organization.slug === currentSlug)) {
        this.$router.push('/dashboard')
        return
      }

      window.location.href = `${ window.location.protocol }//${ process.env.VUE_APP_OMT_FRONT_END_BASE_URL }:${ window.location.port }/choose-organization`
    }
  }
}
</script>

<style scoped>

</style>
