<template>
  <v-container class="pa-10">
    <v-row>
      <v-col class="text-center">
        <span class="grey--text">Recover your password</span>
      </v-col>
    </v-row>

    <v-row v-if="isPasswordRequestSent">
      <v-col>
        <v-row class="pt-6">
          <v-col id="request-sent-message" class="text-center">
            <h4>The request has been sent! Please check your email and follow the instructions.</h4>
          </v-col>
        </v-row>

        <v-row class="pt-6">
          <v-col class="text-center">
            <p>
              Already have an account?
              <v-btn
                id="login-link"
                color="primary"
                link
                plain
                style="color: black"
                @click="$router.push('/login')"
              >
                Log in
              </v-btn>
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col>
        <v-row class="pt-6">
          <v-col class="text-center">
            <p>Enter the information requested below to receive instructions on how to reset your password.</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              id="recover-email"
              v-model="userEmail"
              :disabled="isLoading"
              :error-messages="emailErrors"
              autocomplete="off"
              hint="Please enter your email address."
              label="Email"
              required
              @blur="$v.userEmail.$touch()"
            />
          </v-col>
        </v-row>

        <v-row class="pt-6">
          <v-col class="text-center">
            <v-btn
              id="send-email"
              :disabled="isLoading || $v.$anyError"
              :loading="isLoading"
              color="primary"
              min-width="200"
              raised
              rounded
              x-large
              @click.native="requestPasswordReset"
            >
              Send Email
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center">
            <p>
              Already have an account?
              <a
                id="login-link"
                class=""
                @click="$router.push('/login')"
              >
                Log in
              </a>
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'

export default {
  name: 'AuthForgottenPasswordView',
  auth: 'guest',

  validations: {
    userEmail: {
      required,
      email
    }
  },

  data () {
    return {
      isPasswordRequestSent: false,
      userEmail: '',
      loading: false
    }
  },

  computed: {
    isLoading () {
      return this.loading
    },

    emailErrors () {
      const errors = []

      if (!this.$v.userEmail.$dirty) {
        return errors
      }

      !this.$v.userEmail.required && errors.push('E-mail is required')
      !this.$v.userEmail.email && errors.push('E-mail format is invalid')
      return errors
    }
  },

  methods: {
    async requestPasswordReset () {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }
      try {
        this.loading = true

        await this.$store.dispatch('auth/requestResetPassword', { email: this.userEmail })
        this.isPasswordRequestSent = true
      } catch (e) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: e.response.data.message,
          duration: 4000,
          mode: 'fail'
        })
      } finally {
        this.loading = false
      }
    }
  },

  beforeCreate () {
    this.$store.dispatch('auth/signOut')
  },

  destroyed () {
    this.userEmail = ''
  }
}
</script>
