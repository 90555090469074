<template>
  <div>
    <h1 class="mb-6">
      Users
    </h1>

    <v-divider class="mt-1 mb-8" />

    <user-list-overview-container />
  </div>
</template>

<script>
import UserListOverviewContainer from '@/components/user/UserListOverviewContainer'

export default {
  name: "UsersView",
  components: { UserListOverviewContainer }
}
</script>

<style scoped>

</style>
