<template>
  <v-app-bar app clipped-left>
    <v-img
      id="home-logo-button"
      :aspect-ratio="3.25"
      :src="getLogoImage()"
      contain
      max-height="35"
      max-width="140"
      v-bind="$attrs"
      v-on="$listeners"
      @click.stop="$emit('redirectToHomePage')"
    />

    <v-spacer />

    <v-menu v-if="showAvatarProp && user" offset-y>
      <template #activator="{ on, attrs }">
        <v-tooltip
          bottom
          class="text-center"
          color="grey-darken1"
        >
          <span>{{ user.name }}</span>

          <template #activator="data">
            <v-btn
              id="bar-account-button"
              class="mx-2"
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              plain
            >
              <v-avatar
                size="28"
                v-bind="data.attrs"
                v-on="data.on"
              >
                <img
                  v-if="user.image"
                  :src="user.image.url"
                  alt="profile picture"
                >
                <img
                  v-else
                  src="../assets/img/icons/adult-beard-boy-casual.png"
                  alt="profile picture"
                >
              </v-avatar>
            </v-btn>
          </template>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item id="menu-item-profile" link @click="$router.push(`/users/${user.id}`)">
          <v-list-item-title>Edit Profile</v-list-item-title>
        </v-list-item>

        <v-list-item id="menu-item-change-organization" link @click="$router.push('/choose-organization')">
          <v-list-item-title>Change organization</v-list-item-title>
        </v-list-item>

        <v-list-item id="menu-item-sign-out" link @click.native="logout()">
          <v-list-item-title>Sign Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'TheDefaultLayoutWrapAppBar',
  props: {
    showAvatarProp: {
      default: true
    }
  },

  computed: {
    ...mapGetters(
      {
        user: 'auth/getCurrentUser'
      }
    )
  },

  methods: {
    getLogoImage () {
      return require('@/assets/img/bim-more-organizations-logo.png')
    },

    goToLoginPath () {
      window.location.href = `${ window.location.protocol }//${ process.env.VUE_APP_OMT_FRONT_END_BASE_URL }:${ window.location.port }/login`
    },

    async logout () {
      await this.$store.dispatch('auth/signOut')
      await this.goToLoginPath()
    }
  }
}
</script>

<style scoped>

</style>
