<template>
  <v-container class="pa-10">
    <v-row style="padding-top: 140px; padding-bottom: 140px">
      <v-col class="text-center">
        <span class="grey--text">Your new password has been set successfully.</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row>
          <v-col class="text-center">
            <form @submit.prevent="$router.push('/login')">
              <v-btn
                id="login-btn"
                autofocus
                color="primary"
                raised
                rounded
                type="submit"
                width="200"
                x-large
              >
                Log in
              </v-btn>
            </form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'AuthSetPasswordSuccessView'
}
</script>

<style lang="scss" scoped>
.container {
  width: 80%;
}
</style>
