import { UserClass } from '@/classes/users/UserClass'

export class ProductUsersClass {
  constructor (data = {}) {
    this.members = UserClass.createList(data.members)
    this.owners = UserClass.createList(data.owners)
  }

  rolesForExPan () {
    const result = []

    for (const [eachRole, roleUsers ] of Object.entries(this)) {
      if (roleUsers.length) {
        const role = { name: eachRole, expValue: [0],  indeterminate: false}
        result.push(role)
      }

    }
    return result
  }

  get hasAnyUsers () {
    return !(this.members.length === 0 && this.owners.length === 0)

  }
}