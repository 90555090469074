import { ProductClass } from '@/classes/product/ProductClass'
import { ProductUsersClass } from '@/classes/product/ProductUsersClass'
import ProductService from '@/services/product.service'

import { router } from '../../router/index'

const actionStates = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  ERRORED: "ERRORED",
  NOT_FOUND: "NOT_FOUND",
  DATA_LOADED: "DATA_LOADED",
  DIALOG_SUBMIT_LOADING: "DIALOG_SUBMIT_LOADING",
  DIALOG_SUBMIT_ERROR: "DIALOG_SUBMIT_ERROR"
}

const state = {
  currentState: actionStates.INITIAL,
  products: null,
  product: null,
  productAttachedUsers: null,
  productUsers: null,
  selectedUsers: []
}

const getters = {
  getProducts (state) {
    return state.products
  },

  getProduct (state) {
    return state.product
  },

  getCurrentState (state) {
    return state.currentState
  },

  getProductAttachedUsers (state) {
    return state.productAttachedUsers
  },

  getProductUsers (state) {
    return state.productUsers
  },

  getSelectedUsers (state) {
    return state.selectedUsers
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setProducts (state, products) {
    state.products = products
  },

  setProduct (state, product) {
    state.product = product
  },

  setProductAttachedUsers (state, users) {
    state.productAttachedUsers = users
  },

  setProductUsers (state, users) {
    state.productUsers = users
  },

  setSelectedUsers (state, selectedUsers) {
    state.selectedUsers = selectedUsers
  },

  appendUserToSelectedUsers (state, user) {
    if (!state.selectedUsers.includes(user)) {
      state.selectedUsers.push(user)
    }
  },

  extractUserFromSelectedUsers (state, user) {
    if (state.selectedUsers.includes(user)) {
      const userIndex = state.selectedUsers.findIndex((obj) => obj.id === user.id)
      state.selectedUsers.splice(userIndex, 1)
    }
  }
}

const actions = {
  async fetchProducts (vuexContext) {
    try {
      vuexContext.commit('setActionState', actionStates.LOADING)

      const productsResponse = await ProductService.getAllProducts()
      const products = ProductClass.createList(productsResponse)

      vuexContext.commit('setProducts', products)
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.ERRORED)
    } finally {
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    }
  },

  async fetchProduct (vuexContext, productId) {
    try {
      vuexContext.commit('setActionState', actionStates.LOADING)

      const productResponse = await ProductService.getProduct(productId)

      const product = new ProductClass(productResponse)

      vuexContext.commit('setProduct', product)

      await vuexContext.dispatch('fetchProductAttachedUsers', productId)
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.ERRORED)
    } finally {
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    }
  },

  async fetchProductAttachedUsers (vuexContext, productId) {
    try {
      vuexContext.commit('setActionState', actionStates.LOADING)
      const productUsersResponse = await  ProductService.getProductUsers(productId)

      const productUsers = new ProductUsersClass(productUsersResponse)
      vuexContext.commit('setProductAttachedUsers', productUsers)
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.ERRORED)
    } finally {
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    }

  },

  async attachUsersToProduct (vuexContext, data) {
    try {
      await ProductService.attachUsersToProduct(data)

      await vuexContext.dispatch('fetchProductAttachedUsers', data.product_id)
    } catch (e) {
      await router.replace('/dashboard')
    }
  },

  async detachUsersFromProduct (vuexContext, data) {
    try {
      await ProductService.detachUsersFromProduct(data)

      await vuexContext.dispatch('fetchProductAttachedUsers', data.product_id)
    } catch (e) {
      await router.replace('/dashboard')
    }
  },

  addUserToSelectedUsers (vuexContext, user) {
    vuexContext.commit('appendUserToSelectedUsers', user)
  },

  removeUserFromSelectedUsers (vuexContext, user) {
    vuexContext.commit('extractUserFromSelectedUsers', user)
  },

  modifySelectedUsers (vuexContext, users) {
    vuexContext.commit('setSelectedUsers', users)
  }
}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
