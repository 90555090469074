<template>
  <div
    autofocus
    style="outline: none;"
    tabindex="0"
    @keydown.enter="continueWithOrganization"
  >
    <auth-choose-organization
      :is-list-loading-prop="currentState === actionStateConstants.LOADING"
      :organizations-prop.sync="organizations"
      :selected-organization-prop.sync="selectedOrganization"
      @acceptInvitation="acceptInvitation"
      @declineInvitation="declineInvitation"
      @selectOrganization="selectOrganization"
    />

    <div class="d-flex justify-center mt-15">
      <v-btn
        id="organization-choose-btn"
        :disabled="organizations.length ? !selectedOrganization : false"
        v-if="loaded"
        color="primary"
        raised
        rounded
        type="submit"
        width="200"
        x-large
        @click="handleButtonClick"
      >
        {{ organizations.length ? 'Continue' : 'Logout' }}
      </v-btn>
    </div>
  </div>

</template>

<script>
import AuthChooseOrganization from '@/components/auth/AuthChooseOrganization'
import { actionStates } from '@/constants'
import { mapGetters } from 'vuex'

export default {
  name: "AuthChooseOrganizationContainer",
  components: { AuthChooseOrganization },

  data () {
    return {
      actionStateConstants: actionStates,
      loaded: false,
    }
  },

  computed: {
    ...mapGetters(
      {
        selectedOrganization: 'user/getUserSelectedOrganization',
        organizations: 'user/getUserOrganizations',
        currentState: 'user/getCurrentState'
      }
    )
  },

  methods: {
    async getAllOrganizations () {
      await this.$store.dispatch('user/fetchUserOrganizations')
    },

    selectOrganization (organization) {
      this.$store.dispatch('user/updateUserSelectedOrganization', organization)
    },

    async handleButtonClick () {
      if (this.organizations.length) {
        this.continueWithOrganization()
      } else {
        await this.logout()
      }
    },

    continueWithOrganization () {
      if (this.selectedOrganization?.slug) {
        window.location.href = `${ window.location.protocol }//${ this.selectedOrganization.slug }.${ process.env.VUE_APP_OMT_FRONT_END_BASE_URL }:${ window.location.port }/`
      }
    },

    goToLoginPath () {
      window.location.href = `${ window.location.protocol }//${ process.env.VUE_APP_OMT_FRONT_END_BASE_URL }:${ window.location.port }/login`
    },

    async logout () {
      await this.$store.dispatch('auth/signOut')
      await this.goToLoginPath()
    },


    acceptInvitation (organization) {
      this.$store.dispatch('user/acceptInvitation', organization)
    },

    declineInvitation (organization) {
      this.$store.dispatch('user/declineInvitation', organization)
    }
  },

  async created () {
    try {
      await this.getAllOrganizations()
    } finally {
      this.loaded = true
    }


  }
}
</script>

<style scoped>

</style>
