<template>
  <div class="mx-auto">
    <div class="image-component">
      <v-img
        v-if="imageUrlProp"
        :class="imageCompOptions.class ? imageCompOptions.class : ''"
        :contain="imageCompOptions.contain"
        :cover="imageCompOptions.cover"
        :height="imageCompOptions.height"
        :max-height="imageCompOptions.maxHeight"
        :max-width="imageCompOptions.maxWidth"
        :src="imageUrlProp"
        :alt="`${entityNameProp} image`"
        :width="imageCompOptions.width"
        style=""
      >
      </v-img>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AppImageHolder',


  props: {
    imgCoverProp: {
      type: Boolean,
      required: true
    },

    entityNameProp: {
      type: String,
      required: true
    },

    imageUrlProp: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      imageCompOptions: {
        width: 300,
        height: 300,
        cover: false,
        contain: true,
        class: 'image-border-radius-overview'
      },
      isUpdateImageDialogVisible: false
    }
  },

  methods: {
    showEditImageDialog () {
      this.$emit('showEditImageDialog')
    }
  }
}
</script>

<style lang="scss" scoped>
.loader-container {
  width: 300px;
  height: 300px;
  padding: 50px;
}

.selector-container {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.empty-image-card {
  background: var(--v-grey-lighten2);

  .v-avatar {
    background: var(--v-grey-lighten1);
    transition: background $secondary-transition;
  }

  &:hover {
    cursor: pointer;
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);

    .v-avatar {
      background: var(--v-primary-base);
    }
  }
}
</style>
