<template>
  <div>
    <v-row>
      <v-col
        :style="[styleScrollBarOverflowY, styleScrollBarHeight]"
        class="pa-0 mb-3 pb-4 no-gutters organization-scrollbar"
        cols="12"
        lg="12"
        md="12"
      >
        <list-overview
          :can-create=false
          :cols-prop="{ cols: 12, sm: 12, md: 12, lg: 6, xl: 6 }"
          :data-source="organizationsProp"
          :is-list-loading-prop="isListLoadingProp"
          :selectable="true"
          class="pa-0"
          list-type="organization-login"
        >
          <template #entity-card="{ entity, selectable }">
            <list-item-common-organization
              v-if="entity.invite === undefined"
              :class="highlightSelectedOrganizationClass(entity.id)"
              :entity="entity"
              :selectable="selectable"
              class="cursor-pointer mt-1"
              @click.native="$emit('selectOrganization', entity)"
            />

            <list-item-common-organization-invite
              v-else-if="entity.invite && entity.invite === true"
              :class="highlightSelectedOrganizationClass(entity.id)"
              :entity="entity"
              :selectable="selectable"
              class="cursor-pointer mt-1"
              @acceptInvitation="$emit('acceptInvitation', entity.id)"
              @declineInvitation="$emit('declineInvitation', entity.id)"
            />
          </template>
        </list-overview>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ListOverview from '@/components/list/ListOverview'
import ListItemCommonOrganization from '@/components/list/ListItemCommonOrganization'
import ListItemCommonOrganizationInvite from '@/components/list/ListItemCommonOrganizationInvite'

export default {
  name: "OrganizationListOverview",
  components: {
    ListItemCommonOrganizationInvite,
    ListItemCommonOrganization,
    ListOverview
  },

  props: {
    organizationsProp: {
      type: Array,
      required: true
    },
    isListLoadingProp: {
      type: Boolean,
      required: true
    },
    selectedOrganizationProp: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      organizations: this.organizationsProp,
    }
  },

  computed: {
    styleScrollBarOverflowY () {
      return {
        'overflow-y': this.organizationsProp.length > 4 ? 'scroll' : 'auto'
      }
    },

    styleScrollBarHeight () {
      return {
        height: this.organizationsProp.length === 1 ? 'unset' : '400px'
      }
    }
  },

  methods: {
    highlightSelectedOrganizationClass (entityId) {
      if (this.selectedOrganizationProp) {
        return (entityId === this.selectedOrganizationProp.id) ? 'active-item' : ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.organization-scrollbar {
  overflow-x: hidden;
}

.active-item {
  border: 2px solid #FFC219;
}

.inactive-item {
  border: 1px solid #707070;
  opacity: 0.2;
}

.catalogue-list {
  .catalogue-item {
    padding-left: 27px;
  }
}

.list-container {
  width: 75%;
  @media only screen and (max-width: 1900px) {
    width: 100%;
    padding: 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--v-grey-lighten1);
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--v-primary-base);
  border-radius: 100px;
}

.empty-selected-organization-card {
  background: var(--v-grey-lighten2);
  border: 1px solid #DDDDDD;
  border-radius: 6px;
}

.organization-name {
  overflow: hidden;
  height: 100px;
  line-height: normal;
  font-size: 1.375rem
}
</style>
