import { BaseClass } from '@/classes/base/BaseClass'

export class UserRoleClass extends BaseClass{
  constructor (data= {}) {
    super()

    this.created_at = data.created_at
    this.guard_name = data.guard_name
    this.id = data.id
    this.name = data.name
    this.pivot = data.pivot
    this.updated_at = data.updated_at
  }


  get permissionsMap () {
    return [
      {
        role: 'owner',
        permissions: {
          menus: {
            products: true,
            users: true
          },

          tabs: {
            users: {
              access: true,
            },
            versions: {
              access: true,
              add_new_versions: true,
              edit_version: true
            }
          },
          add_new_products_to_user: true,
          delete_user: true,
          deactivate_user: true
        }
      },

      {
        role: 'member',
        permissions: {
          menus: {
            products: true,
            users: false
          },

          tabs: {
            users: {
              access: false,
            },
            versions: {
              access: true,
              add_new_versions: false,
              edit_version: false
            },
          },
          add_new_products_to_user: false,
          delete_user: false,
          deactivate_user: false
        }

      }
    ]
  }

  get rolePermissions () {
    const rolePermissions = this.permissionsMap.find(item => item.role === this.name)
    if(rolePermissions) {
      return rolePermissions.permissions
    } else {
      throw new Error('user role is not present in permissions map')
    }


  }

}