<template>
  <div class="content-wrapper pa-4">
    <v-layout class="flex-column flex-md-row">
      <product-left-side-panel/>

      <product-right-side-panel />
    </v-layout>
  </div>
</template>

<script>
import ProductLeftSidePanel from '@/components/product/ProductLeftSidePanel'
import ProductRightSidePanel from '@/components/product/ProductRightSidePanel'
export default {
  name: "ProductSingleOverview",
  components: {
    ProductLeftSidePanel,
    ProductRightSidePanel
  },
}
</script>

<style scoped>

</style>