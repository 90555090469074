<template>
  <div class="entity-details-section my-4 mx-auto mx-md-4">
    <app-image-holder
      :can-update-prop="true"
      :image-prop="entityItemProp.image"
      :img-cover-prop="true"
      @showEditImageDialog="$emit('showEditImageDialog')"
    />

    <div class="text-center pt-6">
      <v-hover v-slot="{ hover }">
        <h2 class="hover-text-effect my-1 mx-auto">
          <span
            v-if="entityItemProp.name"
            class="entity-fields-text-span font-weight-bold"
            style="word-break: break-word;"
          >
            {{ entityItemProp.name }}
          </span>

          <span
            v-else-if="!entityItemProp.name"
            class="text-h5 entity-fields-text-span cursor-pointer"
            @click="$emit('showEditNameDialog')"
          >
            Name is empty click here to edit it
          </span>

          <v-btn
            v-if="true"
            :class="{ 'element-invisible': !hover }"
            class="entity-fields-edit-btn ml-1"
            color="primary"
            icon
            small
            @click="$emit('showEditNameDialog')"
          >
            <app-svg
              size="16"
              src="~/assets/img/icons/edit.svg"
            />
          </v-btn>
        </h2>
      </v-hover>
    </div>

    <slot name="other-edit-fields" />
  </div>
</template>

<script>
import AppSvg from '@/components/app/AppSvg'
import AppImageHolder from '@/components/app/image/AppImageHolder'

export default {
  name: "AppSingleItemEditLeftSection",
  components: { AppImageHolder, AppSvg },

  props: {
    entityTypeProp: {
      type: String,
      required: true
    },

    entityItemProp: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.hover-text-effect {
  &:hover {
    color: var(--v-primary-base);
  }
}

.description-text {
  color: var(--v-grey-darken1);
}
</style>
