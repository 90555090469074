<template>
  <app-dialog
    v-model="isDialogVisible"
    :persistent="isLoadingProp"
    :title-prop="'Edit company'"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <v-text-field
        id="company-field"
        v-model="company"
        :error-messages="companyErrors"
        :placeholder="placeholderProp"
        clearable
        label="Company"
        required
        @blur="$v.company.$touch()"
      />
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            :disabled="isLoadingProp"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            :disabled="isButtonDisabled"
            :loading="isLoadingProp"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="submitForm"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import AppDialog from '@/components/app/AppDialog'

export default {
  name: 'UserDialogEditCompany',
  components: { AppDialog },

  props: {
    placeholderProp: {
      type: String,
      default: 'Add company'
    },

    value: {
      type: Boolean,
      default: false,
      required: true
    },

    isLoadingProp: {
      type: Boolean,
      default: false,
      required: true
    },

    userCompanyProp: {
      type: String,
      default: ''
    }
  },

  validations: {
    company: {
      required,
      maxLength: maxLength(255)
    }
  },

  data () {
    return {
      company: this.userCompanyProp
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    isCompanyChangedOnEdit () {
      return this.company === this.userCompanyProp || this.company?.trim() === this.userCompanyProp
    },

    companyErrors () {
      const errors = []

      if (!this.$v.company.$dirty) {
        return errors
      }

      this.isCompanyChangedOnEdit && errors.push('Company should be different than the current company')
      !this.$v.company.maxLength && errors.push('Can contain maximum of 255 characters')
      !this.$v.company.required && errors.push('Must contain at least 1 character')
      return errors
    },

    isButtonDisabled () {
      return (this.isLoadingProp || this.$v.$anyError || this.isCompanyChangedOnEdit)
    }
  },

  methods: {
    hideDialog () {
      this.resetUser()
      this.isDialogVisible = false
    },

    resetUser () {
      this.company = null
    },

    submitForm () {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      this.company = this.company.trim()
      this.$emit('changeUserCompany', this.company)
    }
  }
}
</script>

<style lang="scss" scoped>
.color-light-gray {
  color: var(--v-grey-lighten1);
}
</style>
