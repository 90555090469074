import { BaseClass } from '@/classes/base/BaseClass'
import { UserRoleClass } from '@/classes/users/UserRoleClass'

export class UserClass extends BaseClass {
  constructor (data= {}) {
    super()

    this.company = data.company
    this.company_position = data.company_position
    this.created_at = data.created_at
    this.deleted_at = data.deleted_at
    this.description = data.description
    this.email = data.email
    this.enabled = data.enabled
    this.id = data.id
    this.image = data.image
    this.name = data.name
    this.roles = UserRoleClass.createList(data.roles)
    this.updated_at = data.updated_at
  }

  get access () {
    return this.roles[0].rolePermissions
  }
}