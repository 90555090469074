<template>
  <div>
    <the-default-layout-wrap-app-bar
      :show-avatar-prop="false"
      @redirectToHomePage="goToChooseOrganization"
    />

    <v-main class="d-flex justify-center align-center pa-0" style="height: 100vh">
      <v-container :key="$router.currentRoute.path" fluid>
        <div class="d-flex justify-center align-center flex-column">
          <v-img
            :src="getNoAccessImage"
            contain
            max-height="114"
          />
          <h4 class="mt-10 mb-6 primary--text font-weight-bold">Sorry, you don’t have access to this organization</h4>
          <h5>You don’t have permission to view this page.</h5>
          <h5>Please contact the owner and ask them to invite you.</h5>

          <v-btn
            id="home-button"
            color="primary"
            raised
            rounded
            style="margin-top: 80px"
            width="200px"
            x-large
            @click.prevent="goToChooseOrganization"
          >
            Home
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </div>

</template>

<script>
import TheDefaultLayoutWrapAppBar from '@/layouts/TheDefaultLayoutWrapAppBar'

export default {
  name: "NoAccessOrganizationView",
  components: { TheDefaultLayoutWrapAppBar },

  computed: {
    getNoAccessImage () {
      return require('@/assets/img/no-access.png')
    }
  },

  methods: {
    goToChooseOrganization () {
      window.location.href = `${ window.location.protocol }//${ process.env.VUE_APP_OMT_FRONT_END_BASE_URL }:${ window.location.port }/choose-organization`
    }
  }
}
</script>

<style scoped>

</style>
