import $axios from '../plugins/axios'

const baseAuthURL = `${ window.location.protocol }//${ process.env.VUE_APP_API_OMT_BASE_URL }/api/v1`

class AuthService {
  login (user) {
    return $axios.post(`${ baseAuthURL }/login`, { email: user.email, password: user.password })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getMe () {
    return $axios.get('get-me')
      .then((res) => {
        return res.data
      }).catch((error) => {
        throw error
      })
  }

  getOrganization () {
    return $axios.get('/get-organization')
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  createUserFromInvitation (data) {
    return $axios.post('/create-user-from-invitation', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  requestResetPassword (data) {
    return $axios.post('/request-reset-password', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  resetPassword (data) {
    return $axios.post('/reset-password', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changePassword (data) {
    return $axios.post('/change-password', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new AuthService()
