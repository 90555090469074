import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import $axios from './plugins/axios'
import VueCookies from 'vue-cookies'

import convertDate from './plugins/time.js'

import 'animate.css'
import './styles/_expansion-panel.scss'
import './styles/_avatars.scss'

Vue.config.productionTip = false

Vue.use(Vuelidate)

/** Axios plugin settings */
Vue.use({
  install (Vue) {
    Vue.prototype.$axios = $axios
  }
}
  )

Vue.use(convertDate)

/** Vue cookies */
Vue.use(VueCookies)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
