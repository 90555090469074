<template>
  <div class="flex-grow-1 ma-4">

    <v-container class="pa-0">
      <v-row class="d-flex justify-space-between">
        <!--        *******************-->
        <!--        Tabs-->
        <!--        *******************-->
        <v-col class="text-center">
          <v-tabs
            v-model="selectedTab"
            active-class="tab-active"
            class="mb-4"
            hide-slider
            show-arrows
          >
            <v-tab
              v-if="true"
              key="files"
              :disabled="false"
              href="#products"
            >
              Products
            </v-tab>

          </v-tabs>
        </v-col>

        <!--        *******************-->
        <!--        Activate Deactivate User-->
        <!--        *******************-->
        <v-col class="text-right" v-if="currentUser.id !== user.id && currentUser.access.deactivate_user">
            <v-btn
              :loading="loadingUserActDeact"
              class="px-2 overlay-exclude"
              color="primary"
              rounded
              small
              text
              @click="activateDeactivateUser"
            >
              <div class="guttered-row dense">
                <app-svg
                  size="16"
                  src="~/assets/img/icons/active-user-icon.svg"
                />
                <span>{{ user.enabled ? 'Deactivate user' : 'Activate user' }}</span>
              </div>
            </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div class="tabs-content-section pa-5">
      <v-tabs-items v-model="selectedTab">
        <v-tab-item
          v-if="selectedTab === 'products'"
          key="products"
          value="products"
        >
          <div
            class="guttered-row dense"
            v-bind="$attrs"
            v-on="$listeners"
          >
          </div>

          <div>
            <div
              class="add-new-items-btn d-flex align-center pa-5 my-5"
              v-if="currentUser.access.add_new_products_to_user"
              @click="showEditUserProducts"
            >
              <v-avatar size="60">
                <div class="icon">
                  <app-svg
                    alt="icon"
                    color="white"
                    size="32"
                    src="~/assets/img/icons/plus.svg"
                  />
                </div>
              </v-avatar>

              <div class="section-title ml-5">
                Add new products
              </div>
            </div>

            <div v-if="currentState === actionStateConstants.LOADING">
              <v-skeleton-loader
                v-for="(item, index) in 4"
                :key="`loading-paragraph-${index}`"
                boilerplate
                class="mt-8"
                type="paragraph"
              />
            </div>

            <div v-else-if="currentState === actionStateConstants.DATA_LOADED">
              <app-list-item
                v-for="(item, index) in userProducts"
                :key="`product-${item.id}-${index}`"
                hover
              >
                <v-avatar class="image-border px-1" rounded size="60">
                  <v-img
                    :alt="`${item.name} picture`"
                    :src="item.logo && item.logo.url"
                    contain
                  />
                </v-avatar>

                <template #title>
                  <span>{{ item.name }} </span>
                </template>

                <template #subtitle>
                  <span>{{ item.description }} </span>
                </template>
              </app-list-item>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <user-dialog-edit-products
      v-if="isEditUserProductsDialogVisible"
      :key="editUserProductsDialogKey"
      v-model="isEditUserProductsDialogVisible"
      :products-props="products"
      :user-selected-products-prop.sync="userProducts"
      @saveUserProduct="saveUserProduct"
    />

  </div>
</template>

<script>
import { actionStates } from '@/constants'
import AppSvg from '@/components/app/AppSvg'
import { mapGetters } from 'vuex'
import AppListItem from '@/components/app/AppListItem'
import UserDialogEditProducts from '@/components/user/UserDialogEditProducts'

export default {
  name: "UserEditRightSidePanelContainer",
  components: { UserDialogEditProducts, AppListItem, AppSvg },

  data () {
    return {
      isEditUserProductsDialogVisible: false,
      editUserProductsDialogKey: 0,

      actionStateConstants: actionStates,
      loadingUserActDeact: false
    }
  },

  computed: {
    ...mapGetters(
      {
        currentState: 'user/getCurrentState',
        user: 'user/getUser',
        currentUser: 'auth/getCurrentUser',
        userProducts: 'user/getUserProducts',
        products: 'user/getAllAvailableProducts'
      }
    ),

    selectedTab: {
      get () {
        return this.$route.query.tab
      },
      async set (tab) {
        await this.$router.push({
          query: { ...this.$route.query, tab }
        })
      }
    }
  },

  methods: {
    showEditUserProducts () {
      this.isEditUserProductsDialogVisible = true
      this.editUserProductsDialogKey++
    },

    hideEditUserProducts () {
      this.isEditUserProductsDialogVisible = false
    },

    async saveUserProduct (selectedProductItems) {
      await this.$store.dispatch('user/updateUserProducts', {
        user_id: this.$route.params.id,
        products: selectedProductItems.map((product) => product.id)
      })
      this.hideEditUserProducts()
    },

    activateDeactivateUser () {
      const data = { id: this.$route.params.id }

      this.loadingUserActDeact = true

      this.$store.dispatch(`user/${ this.user.enabled ? 'deactivateUser' : 'activateUser' }`, data)
        .then(async () => {
          await this.$store.dispatch('snackbar/showSnackbarMessage', {
            message: `You have successfully ${ this.user.enabled ? 'activated' : 'deactivated' } user ${ this.user.name }`,
            duration: 4000,
            mode: 'fail'
          })
        })
        .catch(async (e) => {
          await this.$store.dispatch('snackbar/showSnackbarMessage', {
            message: e,
            duration: 4000,
            mode: 'fail'
          })
        })
        .finally(() => {
          this.loadingUserActDeact = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.overlay-exclude {
  pointer-events: auto;
  z-index: 9999 !important;
  opacity: 1;
}
</style>
