<template>
  <v-card
    :ripple="{ class: 'primary--text' }"
    :to="target"
    class="v-card--bordered"
  >
    <v-img
      :src="entity.logo && entity.logo.url"
      class="mx-10"
      contain
      height="150"
    />

    <v-divider />

    <div class="card-content">
      <v-card-title>
        <span v-if="!entity.pending" class="text-h5 list-item-name text-shortened-dots">{{ entity.name }}</span>
        <span v-if="entity.pending" class="text-h5 list-item-name text-shortened-dots">{{ entity.email }}</span>
        <slot name="under-the-name" />
      </v-card-title>

      <v-spacer />

      <!-- <app-checkbox
        v-if="selectable"
        class="select-checkbox"
        @click.native.stop=""
      /> -->
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    entity: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false
    },
    target: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.card-content {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px 20px;

  & > * {
    min-width: 0;
    margin-left: 5px;
    flex-shrink: 0;

    &:first-child {
      margin-left: 0;
    }
  }

  .v-card__title {
    padding: 0;
    flex-shrink: 1;
  }
}

.v-card {
  .select-checkbox {
    opacity: 0;
    visibility: hidden;
    transform: translateX(5px);
    transition: transform $secondary-transition,
    opacity $secondary-transition,
    visibility $secondary-transition;
  }

  &:hover,
  &.selected {
    .select-checkbox {
      opacity: 1;
      visibility: visible;
      transform: translateX(0px);
    }
  }
}
</style>
