<template>
  <div>
    <user-list-overview
      :is-loading-prop="currentState === actionStateConstants.LOADING"
      :users-prop="users"
      @openCreateUserDialog="openCreateUserDialog"
    />

    <user-dialog-create
      :key="createUserDialogKey"
      v-model="isDialogVisible"
      :is-email-already-exist-prop.sync="isEmailAlreadyExist"
      :is-loading-prop="currentState === actionStateConstants.DIALOG_SUBMIT_LOADING"
      :validation-error-message-prop.sync="validationErrorMessage"
      @createUser="createUser"
    />
  </div>
</template>

<script>
import UserListOverview from '@/components/user/UserListOverview'
import UserDialogCreate from '@/components/user/UserDialogCreate'
import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'

export default {
  name: "UserListOverviewContainer",
  components: { UserDialogCreate, UserListOverview },

  data () {
    return {
      isDialogVisible: false,
      createUserDialogKey: 0,
      users: [],

      validationErrorMessage: '',
      isEmailAlreadyExist: false,
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        currentState: 'user/getCurrentState'
      }
    )
  },

  methods: {
    async getUsers () {
      this.users = await this.$store.dispatch('user/getAllUsers')
    },

    async createUser (user) {
      try {
        await this.$store.dispatch('user/inviteNewUser', user)
        this.closeUserCreateDialog()
        await this.getUsers()
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Invitation was sent successfully',
          duration: 4000,
          mode: 'success'
        })
      } catch (error) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Failed to send invitation',
          duration: 4000,
          mode: 'error'
        })

        if (error.response.status === 422) {
          this.isEmailAlreadyExist = true
          this.validationErrorMessage = error.response.data.errors?.email[0]
        }
      }

    },

    openCreateUserDialog () {
      this.createUserDialogKey++
      this.isDialogVisible = true
    },

    closeUserCreateDialog () {
      this.isDialogVisible = false
    }
  },

  mounted () {
    this.getUsers()
  }
}
</script>

<style scoped>

</style>
